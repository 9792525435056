import { client } from '../../default.client';
import { ApiProxyResponse } from '../../types/interfaces';

interface CurrencyAmount {
	KZT: number;
}

interface Balance {
	bonus: CurrencyAmount;
	money: CurrencyAmount;
}

export default function () {
	return client
		.get<ApiProxyResponse<Balance>>('/balance/user')
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}
