import { client } from '../../default.client';

/**
 * @auth - Требуется авторизация
 */
export default function (terminal_id: number) {
	return client
		.post('/smart-lock/v1/open-lock', { terminal_id })
		.then(res => res.data)
		.catch(error => {
			throw error;
		});
}
