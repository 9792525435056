import { FBQ } from 'index';

const loadFBQNoScript = (id: string) => {
	const img = document.createElement('img');
	img.height = 1;
	img.width = 1;
	img.style.display = 'none';
	img.src = `https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1`;
	document.body.appendChild(img);
};

export default async function loadFBQ(appId: string) {
	if (typeof window === 'undefined' || typeof document === 'undefined') {
		console.warn(
			'Meta Pixel can only be initialized in a browser environment.'
		);
		return;
	}

	if (typeof window.fbq === 'function') return;

	const fbq: FBQ = function () {
		fbq.callMethod ? fbq.callMethod(...arguments) : fbq.queue.push(arguments);
	} as unknown as FBQ;

	fbq.queue = [];
	fbq.loaded = true;
	fbq.version = '2.0';
	fbq.push = window.fbq;

	window.fbq = fbq;

	const script = document.createElement('script');
	script.async = true;
	script.src = 'https://connect.facebook.net/en_US/fbevents.js';

	document.head.appendChild(script);

	loadFBQNoScript(appId);

	window.fbq('init', appId);
	window.fbq('track', 'PageView');
}
