import { loadFBQ } from './function';
import { VendorAnalyticsConfig, VendorAnalyticsService } from './types';

export class FBQService implements VendorAnalyticsService {
	private key: string;

	constructor(config: VendorAnalyticsConfig) {
		this.key = config.key;
	}

	initialize() {
		loadFBQ(this.key);
	}

	trackEvent(event: string, data?: Record<string, any>) {
		if (!window.fbq) {
			return;
		}

		if (import.meta.env.VITE_ENV !== 'production') {
			console.log('FBQ event:', event, data);
			return;
		}

		window.fbq('track', event, data);
	}
}
