import { ITown } from '@/types/interfaces/common/address';

const VISIBLE_TOWNS: ITown[] = [
	{
		id: '1',
		title: 'Алматы',
		coordinates: {
			latitude: 43.222015,
			longitude: 76.85125
		},
		translateKey: 'towns.almaty'
	},
	{
		id: '2',
		title: 'Астана',
		coordinates: {
			latitude: 51.160522,
			longitude: 71.47036
		},
		translateKey: 'towns.astana'
	},
	{
		id: '3',
		title: 'Шымкент',
		coordinates: {
			latitude: 42.315514,
			longitude: 69.586916
		},
		translateKey: 'towns.shymside'
	},
	{
		id: '4',
		title: 'Семей',
		coordinates: {
			latitude: 50.404964,
			longitude: 80.24919
		},
		translateKey: 'towns.semey'
	},
	{
		id: '5',
		title: 'Караганда',
		coordinates: {
			latitude: 49.804684,
			longitude: 73.109383
		},
		translateKey: 'towns.karaganda'
	},
	{
		id: '6',
		title: 'Атырау',
		coordinates: {
			latitude: 47.105045,
			longitude: 51.924622
		},
		translateKey: 'towns.atyrau'
	},
	{
		id: '7',
		title: 'Актау',
		coordinates: {
			latitude: 43.641098,
			longitude: 51.198509
		},
		translateKey: 'towns.aktau'
	},
	{
		id: '9',
		title: 'Темиртау',
		coordinates: {
			latitude: 50.058761,
			longitude: 72.953424
		},
		translateKey: 'towns.temirtau'
	},
	{
		id: '10',
		title: 'Актобе',
		coordinates: {
			latitude: 50.300377,
			longitude: 57.154555
		},
		translateKey: 'towns.aktobe'
	},
	{
		id: '11',
		title: 'Уральск',
		coordinates: {
			latitude: 51.204025,
			longitude: 51.370537
		},
		translateKey: 'towns.uralsk'
	},
	{
		id: '12',
		title: 'Талдыкорган',
		coordinates: {
			latitude: 45.017837,
			longitude: 78.382105
		},
		translateKey: 'towns.taldykorgan'
	},
	{
		id: '20',
		title: 'Усть-Каменогорск',
		coordinates: {
			latitude: 49.948313,
			longitude: 82.627857
		},
		translateKey: 'towns.ustkaman'
	},
	{
		id: '24',
		title: 'Конаев',
		coordinates: {
			latitude: 43.854849,
			longitude: 77.061581
		},
		translateKey: 'towns.konaev'
	}
];

const ALL_TOWNS: ITown[] = [
	...VISIBLE_TOWNS,
	{
		id: '8',
		title: 'Павлодар',
		coordinates: { latitude: 52.285577, longitude: 76.940722 },
		translateKey: 'towns.pavlo'
	},
	{
		id: '13',
		title: 'Кызылорда',
		coordinates: { latitude: 44.842563, longitude: 65.502554 },
		translateKey: 'towns.kyzylorda'
	},
	{
		id: '14',
		title: 'Туркестан',
		coordinates: { latitude: 0, longitude: 0 },
		translateKey: 'towns.turkestan'
	},
	{
		id: '15',
		title: 'Экибастуз',
		coordinates: { latitude: 51.72969, longitude: 75.326622 },
		translateKey: 'towns.ekibastuz'
	},
	{
		id: '16',
		title: 'Петропавловск',
		coordinates: { latitude: 54.86186, longitude: 69.139617 },
		translateKey: 'towns.petropavlovsk'
	},
	{
		id: '17',
		title: 'Костанай',
		coordinates: { latitude: 53.214486, longitude: 63.632082 },
		translateKey: 'towns.kostanay'
	},
	{
		id: '18',
		title: 'Кокшетау',
		coordinates: { latitude: 53.29, longitude: 69.42 },
		translateKey: 'towns.kokshetau'
	},
	{
		id: '19',
		title: 'Тараз',
		coordinates: { latitude: 42.899657, longitude: 71.392727 },
		translateKey: 'towns.taraz'
	},
	{
		id: '21',
		title: 'Рудный',
		coordinates: { latitude: 0, longitude: 0 },
		translateKey: 'towns.rudny'
	},
	{
		id: '22',
		title: 'Балхаш',
		coordinates: { latitude: 0, longitude: 0 },
		translateKey: 'towns.balhash'
	},
	{
		id: '23',
		title: 'Жезказган',
		coordinates: { latitude: 0, longitude: 0 },
		translateKey: 'towns.jezkazgan'
	}
];

export { VISIBLE_TOWNS, ALL_TOWNS };
