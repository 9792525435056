import { reactive } from 'vue';
import {
	PROMO_CATEGORY,
	NEAR_CATEGORY,
	POPULAR_CATEGORY,
	RECENT_CATEGORY,
	BAHANDI_CATEGORY,
	KIKIS_CATEGORY,
	SF_CATEGORY
} from './constants';
import { PaginationWrapperModel } from '@/utils/models';
import {
	IFilial,
	IFilialExtended,
	ICategoryWithFilials
} from '@/types/interfaces/navigator';

interface IState {
	promoFilialsCategory: ICategoryWithFilials<IFilial>;
	nearFilialsCategory: ICategoryWithFilials<IFilial>;
	popularFilialsCategory: ICategoryWithFilials<IFilial>;
	recentFilialsCategory: ICategoryWithFilials<IFilial>;
	bahandiFilialsCategory: ICategoryWithFilials<IFilial>;
	kikisFilialsCategory: ICategoryWithFilials<IFilial>;
	sfFilialsCategory: ICategoryWithFilials<IFilial>;
	recentFilial: IFilialExtended | null;
	hasError: boolean;
}

const state = reactive<IState>({
	promoFilialsCategory: {
		...PROMO_CATEGORY,
		filials: new PaginationWrapperModel()
	},
	nearFilialsCategory: {
		...NEAR_CATEGORY,
		filials: new PaginationWrapperModel()
	},
	popularFilialsCategory: {
		...POPULAR_CATEGORY,
		filials: new PaginationWrapperModel()
	},
	recentFilialsCategory: {
		...RECENT_CATEGORY,
		filials: new PaginationWrapperModel()
	},
	bahandiFilialsCategory: {
		...BAHANDI_CATEGORY,
		filials: new PaginationWrapperModel()
	},
	kikisFilialsCategory: {
		...KIKIS_CATEGORY,
		filials: new PaginationWrapperModel()
	},
	sfFilialsCategory: {
		...SF_CATEGORY,
		filials: new PaginationWrapperModel()
	},
	recentFilial: null,
	hasError: false
});

export { IState, state };
