<template>
	<svg
		width="17"
		height="16"
		viewBox="0 0 17 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_10615_11556)">
			<path
				d="M12.5 10.0352C11.3953 10.0352 10.5 9.13982 10.5 8.03516V4.03516C10.5 2.93049 11.3953 2.03516 12.5 2.03516"
				stroke="currentColor"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M12.5 2.03516H13.1667C13.5347 2.03516 13.8333 2.33382 13.8333 2.70182V14.0352"
				stroke="currentColor"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M12.5 10.0352H13.8333"
				stroke="currentColor"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M4.83479 7.69922L4.82812 13.9992"
				stroke="currentColor"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M4.83594 5.7V2"
				stroke="currentColor"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M2.5 2.05469V5.08935V5.02935C2.5 6.50202 3.54467 7.69602 4.83333 7.69602C6.122 7.69602 7.16667 6.50202 7.16667 5.02935V5.08935V2.05469"
				stroke="currentColor"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_10615_11556">
				<rect width="16" height="16" fill="white" transform="translate(0.5)" />
			</clipPath>
		</defs>
	</svg>
</template>
