/**
 * Разделение числа на разряды
 * */
export default function (value: number | null | undefined) {
	if (!value) {
		return '0';
	}

	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}
