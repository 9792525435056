import { ICategory } from '@/types/interfaces/navigator';

export default {
	name: 'promo',
	value: 'categories.promoValue',
	title: 'categories.promoTitle',
	position: 1,
	image:
		'https://rahmet.object.pscloud.io/static-files/assets/takeaway/img/promo.png',
	payload: {
		onlyPromo: true
	}
} as ICategory;
