import { createWebHistory, createRouter } from 'vue-router';
import routes from './routes';
import { useHeader, useNavbar } from '@/utils/composables';

const FAILED_IMPORT_ERROR = 'Failed to fetch dynamically imported module';
const SCRIPT_FAILED_ERROR = 'Importing a module script failed';

const { setNavbarVisibility } = useNavbar();
const { setBalanceVisibility } = useHeader();

const router = createRouter({
	history: createWebHistory(),
	base: import.meta.env.VITE_API_PROXY,
	routes,
	scrollBehavior(to, from, savedPosition) {
		return Promise.resolve(savedPosition || { x: 0, y: 0 });
	}
});

router.onError((error, to) => {
	if (
		error.message.includes(FAILED_IMPORT_ERROR) ||
		error.message.includes(SCRIPT_FAILED_ERROR)
	) {
		if (to && to.fullPath) {
			window.location = to.fullPath;
		} else {
			window.location.reload();
		}
	}
});

router.afterEach((to, from) => {
	if (to.name !== from.name) {
		setNavbarVisibility(!!to.meta.isNavbarActive);
		setBalanceVisibility(!!to.meta.isBalanceVisible);
	}
});

export default router;
