import { RouteLocationNormalizedLoaded, NavigationGuardNext } from 'vue-router';
import providers from '@/utils/plugins/di';

export default function (
	to: RouteLocationNormalizedLoaded,
	from: RouteLocationNormalizedLoaded,
	next: NavigationGuardNext
) {
	const config = providers.application.getCustomConfig();
	if (config) {
		return next({ name: 'Brands' });
	}

	return next();
}
