import { ICoordinates } from '@/types/interfaces/common';
import { client } from '../../default.client';
import { IGetFilialResponse } from '../../types/interfaces/get-filial-response';
import { ApiProxyResponse } from '../../types/interfaces';

interface IPayload {
	filial_id: number;
	coordinates?: ICoordinates | null;
	brand_id?: number;
}

/**
 * Возвращает данные о филиале из навигатора
 */
export const getFilial = (payload: IPayload, abort?: AbortController) => {
	const headers = payload.coordinates
		? {
				'X-Latitude': payload.coordinates.latitude,
				'X-Longitude': payload.coordinates.longitude
		  }
		: {};

	return client
		.get<ApiProxyResponse<IGetFilialResponse>>(
			`takeaway/locations/card/terminal/${payload.filial_id}`,
			{
				params: {
					include: 'takeaway,express,delivery,extra',
					brand_id: payload.brand_id
				},
				headers,
				signal: abort?.signal
			}
		)
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
};

export const getCleanFilial = (filialId: number) => {
	return client
		.get<ApiProxyResponse<Omit<IGetFilialResponse, 'included'>>>(
			`locations/v2/locations/card/terminal/${filialId}`,
			{
				params: {
					include: 'extra'
				}
			}
		)
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
};
