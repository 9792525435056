import { createApp, App } from 'vue';
import UiLoaderOverlay from '@/components/ui/UiLoaderOverlay.vue';
import { LoaderKey } from '@/types/symbols';

const loaderPlugin = {
	install(app: App) {
		const mountPoint = document.createElement('div');
		document.body.appendChild(mountPoint);
		const container = createApp(UiLoaderOverlay).mount(mountPoint);
		app.config.globalProperties.$loader = container;
		app.provide(LoaderKey, container);
	}
};

export { loaderPlugin };
