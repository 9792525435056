export default {
	beforeMount(el: HTMLElement) {
		const input = el.tagName === 'INPUT' ? el : el.querySelector('input');

		if (input) {
			input.addEventListener('keypress', (e: KeyboardEvent) => {
				const char = String.fromCharCode(e.keyCode);
				const isLetter = char.toLowerCase() !== char.toUpperCase();

				if (!isLetter && char !== '-') {
					e.preventDefault();
				}
			});
		}
	}
};
