import { ClientService } from '@/services/client';
import { DeliveryService } from '@/services/delivery';
import { SERVICES } from '@/utils/constants';
import { IDeliveryPlugin } from './types';

const DELIVERY_SERVICES = [SERVICES.delivery.id];

const isDefault = () => ClientService.state.service.id === SERVICES.delivery.id;

const contains = () =>
	DELIVERY_SERVICES.includes(ClientService.state.service.id);

const delivery: IDeliveryPlugin = {
	isDefault,
	contains,
	service: () => DeliveryService
};

export { delivery };
