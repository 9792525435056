import { computed, readonly } from 'vue';
import { KEYS } from './keys';
import { state, IState } from './state';
import {
	compareCartPositions,
	getFormattedAdditives,
	getFormattedAdditivesV2
} from './functions';
import { ICartFilialData, ICartPosition } from './types';
import { CartPositionModel } from './models';
import { RyadomService } from '../ryadom';
import { checkProducts, getOrderCookingTime } from '@/services/api';
import { getPromoLimit, MenuService } from '@/services/menu';
import { createPromoHash } from '@/services/menu/functions/add-promo-to-menu';
import { ClientService } from '@/services/client';
import { debounce, mapObjArrayByKey } from '@/utils/functions';
import { IProduct, IProductsCategory } from '@/types/interfaces/menu';
import { IProductForApi } from '@/types/interfaces/orders';
import { IFilialExtended } from '@/types/interfaces/navigator';
import { ProductsItem } from '@/types/interfaces/ryadom';

const PRODUCT_NOT_DEFINED_ERROR = 'Product is not defined';

const updateExternalCart = debounce(() => {
	if (state.cart.external_id && RyadomService.isKnownIdentity()) {
		RyadomService.addToCart(
			state.cart.external_id,
			state.cart.filial_id,
			state.cart.products
		);
	}
});

const clearExternalCart = () => {
	if (state.cart.external_id && RyadomService.isKnownIdentity()) {
		RyadomService.resetCart(state.cart.external_id);
		state.cart.external_id = undefined;
	}
};

/**
 * Сохранение корзины в sessionStorage раз в определенное время и обновление внешней корзины
 */
const saveToStorages = debounce(({ localOnly } = { localOnly: false }) => {
	sessionStorage.setItem(KEYS.cart, JSON.stringify(state.cart));

	// Закомментил пока не починят сервис cart
	// if (!localOnly) {
	// 	updateExternalCart();
	// }
});

/**
 * Назначение данных филиала в корзине
 */
const setFilialData = (data: ICartFilialData) => {
	state.cart.filial_id = data.filial_id;
	state.cart.service_type = data.service_type;
	state.cart.service_percentage = data.service_percentage;
	state.cart.external_id = data.external_id;
	saveToStorages({ localOnly: true });
};

const setService = (id: string) => {
	state.cart.service = id;
	saveToStorages({});
};

const setComment = (value: string) => {
	state.cart.comment = value;
	saveToStorages({ localOnly: true });
};

/**
 * Получить идентичный продукт из корзины
 */
const getEqualProduct = (
	product: ICartPosition,
	checkPromo = true
): CartPositionModel | undefined => {
	return state.cart.products.find(el => {
		return compareCartPositions(el, product, checkPromo);
	});
};

/**
 * Получить продукт по id
 */
const getProductById = (id: number): CartPositionModel | undefined => {
	for (let i = state.cart.products.length - 1; i >= 0; i--) {
		if (state.cart.products[i].product_id === id) {
			return state.cart.products[i];
		}
	}
};

/**
 * Получить количество продукта по id
 */
const getProductAmountById = (id: number): number => {
	return state.cart.products
		.filter(item => item.product_id === id)
		.reduce((acc, item) => acc + item.amount, 0);
};

/**
 * Получить продукты для аналитики
 */
const getCheckoutProducts = () => {
	return state.cart.products.map(el => ({
		item_id: el.product_id,
		additionals_id: getFormattedAdditives(el.additive_groups).map(a => a.id),
		quantity: el.amount,
		is_cross_sell: el.is_cross_sell
	}));
};

/**
 * Добавление в корзину
 */
const addProduct = (product: ICartPosition) => {
	const sameProduct = getEqualProduct(product);

	if (sameProduct) {
		sameProduct.amount += product.amount;
	} else {
		state.cart.products.push(new CartPositionModel(product));
	}

	saveToStorages();
};

/**
 * Изменить количество продукта
 */
const changeProductAmount = (product: CartPositionModel, amount: number) => {
	const sameProduct = getEqualProduct(product);

	if (sameProduct) {
		sameProduct.amount = amount;
	}

	saveToStorages();
};

/**
 * Фильтрация корзины по продуктам
 */
const filterByProduct = (product: CartPositionModel) => {
	state.cart.products = state.cart.products.filter(el => el !== product);
};

/**
 * Уменьшение количества продукта
 */
const reduceProduct = (product: ICartPosition) => {
	const sameProduct =
		getEqualProduct(product) || getEqualProduct(product, false);
	if (!sameProduct) {
		throw new Error(PRODUCT_NOT_DEFINED_ERROR);
	}

	const difference = sameProduct.amount - product.amount;
	if (difference <= 0) {
		filterByProduct(sameProduct);
	} else {
		sameProduct.amount = difference;
	}

	saveToStorages();
};

/**
 * Уменьшение количества продукта по id
 */
const reduceProductById = (id: number) => {
	const sameProduct = getProductById(id);
	if (!sameProduct) {
		throw new Error(PRODUCT_NOT_DEFINED_ERROR);
	}

	const difference = sameProduct.amount - 1;

	if (difference <= 0) {
		filterByProduct(sameProduct);
	} else {
		sameProduct.amount = difference;
	}

	saveToStorages();
};

/**
 * Удаление из корзины
 */
const removeProduct = (product: ICartPosition) => {
	const sameProduct = getEqualProduct(product);

	if (sameProduct) {
		state.cart.products = state.cart.products.filter(item => {
			return item !== sameProduct;
		});
	}

	saveToStorages();
};

/**
 * Обновление продуктов в корзине
 */
const setProducts = (products: ICartPosition[]) => {
	state.cart.products = products.map(p => new CartPositionModel(p));
	saveToStorages();
};

/**
 * Очистка корзины
 */
const clear = (strict = false) => {
	state.cart.filial_id = 0;
	state.cart.service = undefined;
	state.cart.service_type = 0;
	state.cart.service_percentage = 0;
	state.cart.products = [];
	state.cart.comment = '';
	saveToStorages({ localOnly: true });

	// Закомментил пока не починят сервис cart
	// if (strict) {
	// 	clearExternalCart();
	// }
};

const validate = (filialId: number) => {
	if (state.cart.products.length && filialId !== state.cart.filial_id) {
		clear();
	}
};

/**
 * Проверить корзину на стоп-лист
 */
const checkForStopList = (): Promise<boolean> => {
	let stoppedList: ICartPosition[] = [];
	const payload = {
		filial_id: state.cart.filial_id,
		products: state.cart.products.map(product => ({
			product_id: product.product_id,
			amount: product.amount,
			additives: getFormattedAdditives(product.additive_groups).map(
				add => add.id
			)
		}))
	};

	return checkProducts(payload).then(response => {
		const stoppedProductsIds = response.data.stopped_products;
		const stoppedAdditivesIds = response.data.stopped_additives;

		if (stoppedProductsIds.length || stoppedAdditivesIds.length) {
			// продукты в стоп-листе
			if (stoppedProductsIds.length) {
				stoppedList = state.cart.products
					.filter(el => stoppedProductsIds.includes(el.product_id))
					.map(el => ({ ...el.getProperties(), is_stopped: true }));
			}

			// дополнения в стоп-листе
			if (stoppedAdditivesIds.length) {
				MenuService.clearAdditiveGroups();

				const productsWithStoppedAdditives: ICartPosition[] = [];
				state.cart.products.forEach(el => {
					let hasStoppedAdditives = false;

					for (const add of getFormattedAdditives(el.additive_groups)) {
						if (stoppedAdditivesIds.includes(add.id)) {
							hasStoppedAdditives = true;
							break;
						}
					}

					if (hasStoppedAdditives) {
						productsWithStoppedAdditives.push({
							...el.getProperties(),
							is_stopped: true
						});
					}
				});

				stoppedList = stoppedList.concat(productsWithStoppedAdditives);
			}

			if (stoppedList.length) {
				const newProducts: ICartPosition[] = [];
				const stoppedListMap = mapObjArrayByKey<ICartPosition>(
					stoppedList,
					'product_id'
				);

				for (const el of state.cart.products) {
					const product = stoppedListMap.get(el.product_id);
					newProducts.push(product || el.getProperties()); // добавить обновленный продукт, если он есть
				}

				setProducts(newProducts);
				return true;
			}
		}

		return false;
	});
};

const setStoppedProducts = (products: number | number[]) => {
	if (Array.isArray(products)) {
		state.cart.products.forEach(p => {
			if (products.includes(p.product_id)) {
				p.is_stopped = true;
			}
		});
	} else {
		state.cart.products.forEach(p => {
			if (p.product_id === products) {
				p.is_stopped = true;
			}
		});
	}

	saveToStorages();
};

/**
 * Найти продукт в меню
 */
const findProductInMenu = (
	product: ICartPosition,
	menu: IProductsCategory[]
): IProduct | undefined => {
	let match;

	for (const category of menu) {
		match = category.relationships.find(productInMenu => {
			return product.name === productInMenu.attributes.name;
		});

		if (match) {
			break;
		}
	}

	return match;
};

/**
 * Отличается ли продукт в корзине от меню
 */
const isProductDifferentFromMenu = (
	product: ICartPosition,
	menuProduct: IProduct
): boolean => {
	if (product.product_id !== menuProduct.id) {
		return true;
	}

	if (product.price !== menuProduct.attributes.price) {
		return true;
	}

	return product.promo_id !== menuProduct.attributes.promo_id;
};

/**
 * Имеются ли дополнения, если продукты в корзине и меню не равны
 */
const hasAdditivesByMenuProduct = (
	product: ICartPosition,
	menuProduct: IProduct
) => {
	return (
		product.product_id !== menuProduct.id &&
		getFormattedAdditives(product.additive_groups).length > 0
	);
};

/**
 * Проверка корзины на наличие в меню
 */
const checkProductsAvailability = (
	menu: IProductsCategory[],
	type: 'default' | 'fridge' = 'default'
): boolean => {
	let cartHasUnavailableProducts = false;

	state.cart.products.map(product => {
		const menuProduct = findProductInMenu(product, menu);

		if (!menuProduct) {
			product.is_unavailable = true;
		} else if (isProductDifferentFromMenu(product, menuProduct)) {
			product.product_id = menuProduct.id;
			product.price = menuProduct.attributes.price;
			product.description = menuProduct.attributes.description || '';
			product.is_unavailable = false;

			// оставить для холодильников начальные состояния стоп-листов
			if (type !== 'fridge') {
				product.is_stopped = menuProduct.attributes.is_stopped;
			}

			if ('promo_id' in menuProduct.attributes) {
				product.promo_id = menuProduct.attributes.promo_id;
				product.promo_price = menuProduct.attributes.promo_price;
				product.discount = menuProduct.attributes.discount;
			} else {
				delete product.promo_id;
				delete product.promo_price;
				delete product.discount;
			}
		} else {
			product.is_unavailable = hasAdditivesByMenuProduct(product, menuProduct);
		}

		if (product.is_unavailable) {
			cartHasUnavailableProducts = true;
		}

		return product;
	});

	saveToStorages();
	return cartHasUnavailableProducts;
};

/**
 * Количество продуктов в корзине
 */
const productsAmount = computed(() => {
	return state.cart.products.reduce((acc, item) => item.getAmount() + acc, 0);
});

/**
 * Сумма продуктов в корзине
 */
const productsSum = computed(() => {
	return state.cart.products.reduce((acc, item) => {
		return item.getActualTotalPriceWithAmount() + acc;
	}, 0);
});

/**
 * Сумма за обслуживание
 */
const serviceSum = computed(() => {
	if (!state.cart.service_percentage) {
		return 0;
	}

	const sum = productsSum.value;
	return Math.floor((sum * state.cart.service_percentage) / 100);
});

/**
 * Сумма акционных продуктов в корзине
 */
const promoSum = computed(() => {
	return state.cart.products.reduce((acc, item) => {
		return item.getPromoDiffTotalPriceWithAmount() + acc;
	}, 0);
});

/**
 * Общая сумма в корзине
 */
const totalSum = computed(() => {
	const sum = productsSum.value;
	const percentage = serviceSum.value;
	return Math.floor(sum + percentage); // округление в пользу клиента
});

/**
 * Количество акционных продуктов в корзине
 */
const promoProductsAmount = computed(() => {
	return state.cart.products
		.filter(item => item.hasPromo())
		.reduce((acc, item) => item.getAmount() + acc, 0);
});

/**
 * Получение кол-ва продуктов в корзине с опред. акцией
 */
const getProductsAmountByPromo = (promoId: number) => {
	return state.cart.products
		.filter(item => item.promo_id === promoId)
		.reduce((acc, item) => item.getAmount() + acc, 0);
};

/**
 * Имеются ли стоп-лист продукты в корзине
 */
const hasStoppedProducts = computed(() => {
	return state.cart.products.some(el => el.is_stopped);
});

/**
 * Имеются ли недоступные продукты в корзине
 */
const hasUnavailableProducts = computed(() => {
	return state.cart.products.some(el => el.is_unavailable);
});

/**
 * Получить остаток акционных продуктов
 */
const getPromoLimitRemainder = (payload: {
	filialId: number;
	promoId: number;
	productLimit: number;
	productDayLimit: number;
}) => {
	const limit = getPromoLimit(payload.productLimit, payload.productDayLimit);

	if (limit) {
		if (state.cart.filial_id !== payload.filialId) {
			return limit;
		}

		const amount = getProductsAmountByPromo(payload.promoId);
		return limit - amount;
	} else {
		return 0;
	}
};

const getFormattedProductsForApi = (): IProductForApi[] => {
	return state.cart.products.map(el => ({
		product_id: el.product_id,
		product_name: el.name,
		promo_id: el.promo_id || null,
		amount: el.amount,
		is_cross_sell: el.is_cross_sell,
		additive_groups: getFormattedAdditivesV2(el.additive_groups)
	}));
};

/**
 * Время готовки блюд в корзине
 */
const fetchCookingTime = (filialId: number) => {
	return getOrderCookingTime(filialId, getFormattedProductsForApi());
};

const formatFromRyadomCart = (
	products: ProductsItem[],
	categories: IProductsCategory[]
) => {
	return products.reduce<ICartPosition[]>((result, product) => {
		const productId = +product.product_id;
		const productQuantity = +product.quantity;
		const uniqueProductIds: Record<string, boolean> = {};

		categories.forEach(category => {
			category.relationships.forEach(product => {
				if (product.id === productId) {
					if (uniqueProductIds[product.id]) {
						return;
					}

					uniqueProductIds[product.id] = true;
					result.push({
						barcode: product.attributes.barcode,
						name: product.attributes.name,
						price: product.attributes.price,
						description: product.attributes.description || '',
						image_url: product.attributes.image_url,
						is_stopped: false,
						amount: productQuantity,
						product_id: product.id,
						is_cross_sell: false,
						is_unavailable: false,
						additive_groups: [],
						promo_id: undefined,
						promo_price: undefined,
						discount: undefined
					});
				}
			});
		});

		return result;
	}, []);
};

const fetchRyadomCart = async (filialId: number, filial: IFilialExtended) => {
	if (!RyadomService.isKnownIdentity()) {
		return;
	}

	const { categories, externalId } = await MenuService.fetchMenu(filialId);

	if (externalId) {
		const ryadomCart = await RyadomService.fetchCart(externalId);

		if (ryadomCart) {
			const { products } = ryadomCart;
			const formattedCart = formatFromRyadomCart(products, categories);

			if (formattedCart.length) {
				clear();
				setService(ClientService.state.service.id);
				setFilialData({
					filial_id: filialId,
					service_percentage: filial?.service_percentage || 0,
					service_type: filial?.service_type || 0,
					external_id: externalId
				});
				setProducts(formattedCart);
				saveToStorages({ localOnly: true });
			}
		}
	}
};

const actualizeProduct = (product: ICartPosition, filialId: number) => {
	if (!MenuService.state.promos.length) {
		return product;
	}

	const hash = createPromoHash(
		MenuService.state.promos,
		MenuService.state.promoProducts
	);

	const promo = hash[product.product_id];
	if (!promo) {
		return product;
	}

	const promoLimitRemainder = getPromoLimitRemainder({
		filialId,
		promoId: promo.promoId,
		productLimit: promo.productLimit,
		productDayLimit: promo.productDayLimit
	});

	if (promoLimitRemainder && !product.promo_id) {
		return {
			...product,
			promo_id: promo.promoId,
			discount: promo.discount,
			promo_price: promo.promoPrice
		};
	}

	return product;
};

const CartService = {
	state: readonly(state) as IState,
	setFilialData,
	setService,
	setComment,
	getEqualProduct,
	getProductAmountById,
	getCheckoutProducts,
	addProduct,
	changeProductAmount,
	reduceProduct,
	reduceProductById,
	removeProduct,
	setProducts,
	clear,
	validate,
	checkForStopList,
	setStoppedProducts,
	checkProductsAvailability,
	fetchRyadomCart,
	productsAmount,
	productsSum,
	serviceSum,
	promoSum,
	totalSum,
	promoProductsAmount,
	hasStoppedProducts,
	hasUnavailableProducts,
	getPromoLimitRemainder,
	getFormattedProductsForApi,
	fetchCookingTime,
	actualizeProduct
};

export { CartService };
export * from './functions';
export * from './models';
export * from './types';
export * from './keys';
