import { AxiosResponse } from 'axios';
import { client } from '../../default.client';
import { getBillAdapter } from '../../adapters/bill';
import { IBill } from '@/services/bill/types';
import { IBillDetails } from '@/types/interfaces/orders';

export default function (preorderId: string | number): Promise<IBillDetails> {
	return client
		.get<AxiosResponse<IBill>>(
			`/smart-receipt/v1/receipt/preorder/${preorderId}`
		)
		.then(response => getBillAdapter(response.data.data))
		.catch(error => {
			throw error;
		});
}
