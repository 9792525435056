import { client } from '../../default.client';

const clearRyadomCart = (
	warehouseId: string,
	deviceId: string
): Promise<void> => {
	return client
		.post(
			'/cart/v1/guest/cart/clear',
			{
				warehouse_id: warehouseId
			},
			{
				headers: {
					'X-Device-Id': deviceId
				}
			}
		)
		.then(res => res.data)
		.catch(error => {
			throw error;
		});
};
const clearRyadomCartAuth = (warehouseId: string): Promise<void> => {
	return client
		.post('/cart/v1/cart/clear', {
			warehouse_id: warehouseId
		})
		.then(res => res.data)
		.catch(error => {
			throw error;
		});
};

export { clearRyadomCart, clearRyadomCartAuth };
