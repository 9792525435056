import { client } from '../../default.client';
import { GET_TRACK_ID_REQUEST } from '../../constants';

interface IResponse {
	data: {
		id: string;
		type: string;
		attributes: { expires_in: number };
	};
}

/**
 * Получение Track id
 */
export default function (): Promise<IResponse> {
	return client
		.post(GET_TRACK_ID_REQUEST)
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}
