import { client } from '../../default.client';
import { AUTH_REQUEST } from '../../constants';
import { IAuthResponse } from '../../types/interfaces/auth';
import { CLIENT_ID } from '@/utils/constants';

/**
 * Авторизация через Code
 */
export default function (
	code: string,
	redirectUri: string,
	idempotencyKey?: string
): Promise<IAuthResponse> {
	return client
		.post(
			AUTH_REQUEST,
			{
				client_id: CLIENT_ID,
				grant_type: 'authorization_code',
				redirect_uri: redirectUri,
				code
			},
			{
				headers: {
					'X-Idempotency-key': idempotencyKey
				}
			}
		)
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}
