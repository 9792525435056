import {
	VendorAnalyticsConfig,
	VendorAnalyticsService,
	VendorAnalyticsFacade
} from './types';
import { GTMService } from './GTMService';
import { FBQService } from './FBQService';

const createService = (
	config: VendorAnalyticsConfig
): VendorAnalyticsService => {
	switch (config.type) {
		case 'gtm':
			return new GTMService(config);
		case 'fbq':
			return new FBQService(config);
		default:
			throw new Error(`Unknown service ${config.type}`);
	}
};

const VendorAnalytics: VendorAnalyticsFacade = {
	service: null,
	init: function (config: VendorAnalyticsConfig) {
		this.service = createService(config);
		this.service.initialize();
	},
	track: function (event: string, data?: unknown) {
		if (this.service) {
			this.service.trackEvent(event, data);
		}
	}
};

export { VendorAnalytics };
