/**
 * Проверяет, содержится ли источник в массиве источников
 */
export default function (
	source: string,
	sources: string[] | string,
	matches = false
): boolean {
	const sourceUppercase = source.toUpperCase();

	if (Array.isArray(sources)) {
		return sources.some(s => {
			const temp = s.toUpperCase();

			if (matches) {
				return sourceUppercase.includes(temp);
			}

			return sourceUppercase === temp;
		});
	}

	if (matches) {
		const temp = sources.toUpperCase();
		return sourceUppercase.includes(temp);
	}

	return sourceUppercase === sources;
}
