import { Analytics, events } from '..';

interface IConfirmSearchPayload {
	query: string;
}

interface ISearchAnalytics {
	trackOnConfirmSearch: (payload: IConfirmSearchPayload) => void;
}

const searchAnalytics: () => ISearchAnalytics = () => ({
	trackOnConfirmSearch: (payload: IConfirmSearchPayload) => {
		Analytics.logEvent(events.SEARCH_CONFIRM, payload);
	}
});

export default searchAnalytics;
