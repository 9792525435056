<template>
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_10615_11547)">
			<path
				d="M9.5 5C10.3284 5 11 4.32843 11 3.5C11 2.67157 10.3284 2 9.5 2C8.67157 2 8 2.67157 8 3.5C8 4.32843 8.67157 5 9.5 5Z"
				stroke="currentColor"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M3.5 6.59918C3.5 6.59918 5.5 4.9948 8.5 7.03668C11.6544 9.18043 13.5 8.3398 13.5 8.3398"
				stroke="currentColor"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M6.91406 10.0742C8.02844 10.3142 10.9991 11.2517 10.9991 14.5017"
				stroke="currentColor"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M8.4025 6.96875C8.02313 8.45187 6.17562 12.9169 2 12.4994"
				stroke="currentColor"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_10615_11547">
				<rect width="16" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
