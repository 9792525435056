import i18n from '@/utils/plugins/i18n';
import { isDayAndNight, getFirstAndLastDayHours } from '@/services/navigator';
import { IHours, IScheduleItem } from '@/types/interfaces/common';

/**
 * Получение текста часов работы
 */
const getWorkingHoursText = (hours: IHours, withPrefixes = true): string => {
	if (isDayAndNight(hours)) {
		return i18n.global.t('schedule.dayAndNight');
	}

	if (withPrefixes) {
		return i18n.global.t('common.fromTo', { ...hours });
	}

	return `${hours.from}-${hours.to}`;
};

/**
 * Получение текста часов работы дня недели
 */
const getWorkingHoursTextByWeekday = (
	weekday: number,
	schedule: IScheduleItem[]
) => {
	const dayHours = getFirstAndLastDayHours(weekday, schedule);

	if (!dayHours) {
		return '';
	}

	return getWorkingHoursText(dayHours);
};

export { getWorkingHoursText, getWorkingHoursTextByWeekday };
