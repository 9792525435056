import { ICartAdditiveGroup, ICartAdditive } from '../types';
import { IAdditiveGroup } from '@/types/interfaces/orders';

const getFormattedAdditives = (
	groups: ICartAdditiveGroup[]
): ICartAdditive[] => {
	return groups.map(g => g.additives).flat();
};

const getFormattedAdditivesV2 = (
	groups: ICartAdditiveGroup[]
): IAdditiveGroup[] => {
	return groups.map(group => {
		return {
			id: group.id,
			additives: group.additives.map(additive => {
				return {
					id: additive.id,
					amount: additive.count || 0
				};
			})
		};
	});
};

export { getFormattedAdditives, getFormattedAdditivesV2 };
