const CLIENT_ID_HASH = import.meta.env.VITE_CLIENT_DOMAIN_HASH;

let clientId: string = import.meta.env.VITE_CLIENT_ID;

try {
	const json = JSON.parse(CLIENT_ID_HASH);
	if (json[window.location.host]) {
		clientId = json[window.location.host];
	}
} catch {
	console.warn('No env.VITE_CLIENT_DOMAIN_HASH was provided');
}

export { clientId as CLIENT_ID };
