import { ICoordinates } from '@/types/interfaces/common';
import { chocofoodClient } from '../../chocofood.client';
import { IReverseGeocoding } from '@/types/interfaces/chocofood';
import getReverseGeoAdapter from '../../adapters/chocofood/get-reverse-geo.adapter';

/**
 * Выдача адресов по координатам
 */
export default function (
	coordinates: ICoordinates
): Promise<IReverseGeocoding[]> {
	const params = {
		lat: coordinates.latitude,
		long: coordinates.longitude
	};

	return chocofoodClient
		.get('geo-service/reverse-geocode/', { params })
		.then(response => getReverseGeoAdapter(response.data.data))
		.catch(error => {
			throw error;
		});
}
