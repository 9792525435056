/**
 * Получение query параметров из URL
 */
export default function (name: string, url = window.location.href) {
	// eslint-disable-next-line no-useless-escape
	name = name.replace(/[\[\]]/g, '\\$&');
	const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
	const results = regex.exec(url);

	if (!results) {
		return '';
	}

	if (!results[2]) {
		return '';
	}

	try {
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	} catch (e) {
		console.error('Error decoding URI component', e);
		return null;
	}
}
