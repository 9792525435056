export enum KEYS {
	TOWN_ID = 'townId',
	PLATFORM = 'platform',
	VERSION = 'version',
	SERVICE = 'service.v3',
	DEVICE_ID = 'deviceId'
}

export enum PLATFORMS {
	WEB = 'web',
	IOS = 'ios',
	ANDROID = 'android'
}

export interface IPaymentOptions {
	backlink: string;
	successUrl: string;
	failureUrl: string;
	displayType?: string;
	includeOrderId?: boolean;
	innerRedirect?: boolean;
	is_chocobalance_disabled?: boolean;
}

export const isPlatforms = (value: string): value is PLATFORMS => {
	return (Object.values(PLATFORMS) as string[]).includes(value);
};
