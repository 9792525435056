import { client } from '../../default.client';
import { ApiProxyResponse, IPayloadProduct } from '../../types/interfaces';

interface IPayload {
	filial_id: number;
	products: IPayloadProduct[];
}

interface Response {
	stopped_products: number[];
	stopped_additives: number[];
}

/**
 * Проверяет продукты на стоп-листы
 */
export default function (
	payload: IPayload
): Promise<ApiProxyResponse<Response>> {
	// eslint-disable-next-line
	return client
		.post('menu/v2/stop_list/order_items/check', payload)
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}
