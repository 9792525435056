export const API_ERROR_NAME = 'API Error';
export const ALLOW_URLS = [/takeaway-web\.(chocodev|choco|test\.chocodev)\.kz/];
export const IGNORE_ERRORS = [
	// Интернет
	'Network Error',
	'Importing a module script failed',
	'Unable to preload CSS',
	'Load failed',

	// Кэш
	'Unexpected end of script',

	// Ошибки axios
	'timeout exceeded',
	'Record not found',
	'Request aborted',
	'AbortError',

	// Когда загружается страница и пользователь нажимает на кнопку "закрыть"
	'Failed to fetch',
	'NetworkError when attempting to fetch resource',
	'cancelled',
	'canceled',
	'отменено',
	'Не удалось завершить операцию',
	'error loading dynamically imported module',

	// Ошибка webView/IOS - вызвана взаимодействием между webview/iframe/worker. Повлиять не можем.
	'window.webkit.messageHandlers',

	// Random plugins/extensions
	'top.GLOBALS',

	// Google Translate
	'__gCrWeb',

	// SyntaxError
	'SyntaxError: Unexpected EOF',
	'Unexpected EOF',

	// При запуске видео, если запрещено аудио
	'play() can only be initiated by a user gesture'
];
