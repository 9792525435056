import { loadGTM } from './function';
import { VendorAnalyticsConfig, VendorAnalyticsService } from './types';

export class GTMService implements VendorAnalyticsService {
	private key: string;

	constructor(config: VendorAnalyticsConfig) {
		this.key = config.key;
	}

	initialize() {
		loadGTM(this.key);
	}

	trackEvent(event: string, data?: Record<string, any>) {
		if (!window.dataLayer) {
			return;
		}

		if (import.meta.env.VITE_ENV !== 'production') {
			console.log('GTM event:', event, data);
			return;
		}

		window.dataLayer.push({
			event,
			...data
		});
	}
}
