import { client } from '../../default.client';
import { ApiProxyResponse } from '../../types/interfaces';
import { ICoordinates } from '@/types/interfaces/common';
import { IDelivertZoneResponse } from '@/services/delivery/types';

const getInDeliveryZone = (url: string, coordinates: ICoordinates) => {
	return client
		.get<ApiProxyResponse<IDelivertZoneResponse>>(url, {
			headers: {
				'X-Latitude': coordinates.latitude,
				'X-Longitude': coordinates.longitude
			}
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
};

const getInDeliveryZoneByBrand = (
	brandId: string,
	coordinates: ICoordinates
) => {
	return getInDeliveryZone(
		`brandy/api/v1/brands/${brandId}/delivery-zones/check`,
		coordinates
	);
};

const getInDeliveryZoneByLocation = (
	locationId: string,
	coordinates: ICoordinates
) => {
	return getInDeliveryZone(
		`brandy/api/v1/locations/${locationId}/delivery-zones/check`,
		coordinates
	);
};

export { getInDeliveryZoneByBrand, getInDeliveryZoneByLocation };
