import providers from './index';
import { IDiProvide } from './types';
import { ProvidersKey } from '@/types/symbols';

const DependencyInjectionPlugin = {
	install: ({ provide }: IDiProvide) => {
		providers.application.init();
		provide(ProvidersKey, providers);
	}
};

export { DependencyInjectionPlugin };
