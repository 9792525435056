import { IAddressSuggestion } from '@/types/interfaces/chocofood';
import getAddressSuggestionsAdapter from '../../adapters/chocofood/get-address-suggestions.adapter';
import { chocofoodClient } from '../../chocofood.client';

/**
 * Предложения по введенному адресу
 */
export default function (address: string): Promise<IAddressSuggestion[]> {
	const params = {
		address,
		limit: 3
	};

	return chocofoodClient
		.get('geo-service/suggestions/', { params })
		.then(response => getAddressSuggestionsAdapter(response.data.data))
		.catch(error => {
			throw error;
		});
}
