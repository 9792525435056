import { h, ref, VNode } from 'vue';
import providers from '../plugins/di';
import UiButtonClose from '@/components/ui/UiButtonClose.vue';

// Распространяются глобально
const headerVNodes = ref<VNode[]>([]);
const isBalanceVisible = ref(false);

const addHeaderVNode = (node: VNode) => {
	headerVNodes.value.unshift(node);
};

const removeHeaderVNode = (node: VNode) => {
	headerVNodes.value = headerVNodes.value.filter(v => v !== node);
};

const setBalanceVisibility = (value: boolean) => {
	isBalanceVisible.value = value;
};

// Крестик для закрытия миниаппа
if (providers.application.isWebView()) {
	const closeButton = h(UiButtonClose, {
		onClick: providers.application.backToApp
	});

	addHeaderVNode(closeButton);
}

export default function () {
	return {
		headerVNodes,
		isBalanceVisible,
		addHeaderVNode,
		removeHeaderVNode,
		setBalanceVisibility
	};
}
