import { client } from '../../default.client';
import { ApiProxyResponse } from '../../types/interfaces';
import { BrandFilial, FilialsByBrandNameHash } from '@/types/interfaces/brands';
import { ICoordinates } from '@/types/interfaces/common';

const getBrandNameHash = (filials: BrandFilial[]): FilialsByBrandNameHash => {
	const hash: FilialsByBrandNameHash = {};

	filials.forEach(filial => {
		if (!hash[filial.brand_name]) {
			hash[filial.brand_name] = [];
		}

		hash[filial.brand_name].push(filial);
	});

	return hash;
};

export default function (payload: {
	brandId: string;
	townId: number;
	coordinates?: ICoordinates | null;
	page?: number;
	limit?: number;
}) {
	return client
		.get<ApiProxyResponse<BrandFilial[]>>(
			`/brandy/api/v1/locations/brand/${payload.brandId}`,
			{
				headers: {
					'X-Latitude': payload.coordinates?.latitude,
					'X-Longitude': payload.coordinates?.longitude
				},
				params: {
					town_id: payload.townId,
					page: payload.page || 1,
					limit: payload.limit || 20
				}
			}
		)
		.then(response => getBrandNameHash(response.data.data))
		.catch(error => {
			throw error;
		});
}
