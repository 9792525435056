import { computed, reactive, readonly } from 'vue';
import { CATEGORY_HASH } from './constants';
import { NEAR_CATEGORY } from '../navigator/constants';
import { ClientService } from '@/services/client';
import { getFilials, getCleanFilial, getFilialsByBrand } from '@/services/api';
import { DeliveryService } from '@/services/delivery';
import { SERVICES } from '@/utils/constants';
import { PaginationWrapperModel } from '@/utils/models';
import { IFilial, ICategoryWithFilials } from '@/types/interfaces/navigator';

interface ICallbacks {
	onStart: () => unknown;
	onFinish: () => unknown;
	onError: (error: Error) => unknown;
}

interface IState {
	filials: PaginationWrapperModel<IFilial>;
	categories: Record<number, ICategoryWithFilials<IFilial>>;
}

const state = reactive<IState>({
	filials: new PaginationWrapperModel(),
	categories: {}
});

interface FetchFilialsPayload {
	brand_id: string;
	page?: number;
}

interface FetchCategoryPayload extends FetchFilialsPayload {
	category_id: number;
}

interface FetchCategoriesPayload extends FetchFilialsPayload {
	category_ids: number[];
}

const fetchFilials = (
	{ brand_id, page }: FetchFilialsPayload,
	abort?: AbortController
) => {
	if (state.filials.hasResults() && !page) {
		return Promise.resolve(state.filials);
	}

	let coordinates = ClientService.state.coordinates;
	if (ClientService.state.service.id === SERVICES.delivery.id) {
		coordinates = DeliveryService.activeAddressCoordinates.value;
	}

	const payload = {
		include: 'promo',
		service: ClientService.state.service.id,
		town: ClientService.state.townId,
		coordinates,
		brand_id,
		page: page || 1,
		url: NEAR_CATEGORY.payload.url
	};

	return getFilials(payload, abort).then(response => {
		state.filials.addResults(response);
		return state.filials;
	});
};

const fetchCategory = async (
	{ brand_id, page, category_id }: FetchCategoryPayload,
	abort?: AbortController
) => {
	const category = CATEGORY_HASH[category_id];
	if (!category) {
		return;
	}

	if (state.categories[category_id]) {
		return;
	}

	let coordinates = ClientService.state.coordinates;
	if (ClientService.state.service.id === SERVICES.delivery.id) {
		coordinates = DeliveryService.activeAddressCoordinates.value;
	}

	const payload = {
		include: 'promo',
		service: ClientService.state.service.id,
		town: ClientService.state.townId,
		coordinates,
		brand_id,
		page: page || 1,
		url: NEAR_CATEGORY.payload.url,
		category_id: category_id
	};

	const results = await getFilials(payload, abort);
	if (!state.categories[category_id]) {
		state.categories[category_id] = {
			...category,
			filials: new PaginationWrapperModel()
		};
	}

	state.categories[category_id].filials.addResults(results);
};

const fetchCategories = async (
	{ brand_id, page, category_ids }: FetchCategoriesPayload,
	abort?: AbortController
) => {
	const promises = [];
	for (const id of category_ids) {
		promises.push(fetchCategory({ brand_id, page, category_id: id }, abort));
	}

	return Promise.all(promises);
};

const loadMoreFilials = (
	payload: FetchFilialsPayload,
	cb: ICallbacks = {
		onStart: () => {},
		onError: () => {},
		onFinish: () => {}
	}
) => {
	if (state.filials.page < state.filials.lastPage) {
		cb.onStart();
		return fetchFilials({
			...payload,
			page: state.filials.page + 1
		})
			.catch((error: Error) => {
				cb.onError(error);
			})
			.finally(() => cb.onFinish());
	}

	return Promise.resolve(state.filials);
};

const fetchSubFilials = (subbrand: string, page = 1) => {
	return getFilials({
		include: 'promo',
		town: ClientService.state.townId,
		coordinates: ClientService.state.coordinates,
		search: subbrand,
		page
	});
};

const fetchFilialLink = (filialId: number) => {
	return getCleanFilial(filialId).then(res => {
		const whatsapp = res.data.location.extra?.social_networks.find(network => {
			return network.name === 'whatsapp';
		});

		if (!whatsapp) {
			throw new Error('NO_WHATSAPP_ERROR');
		}

		return whatsapp.link;
	});
};

const categories = computed(() => {
	return Object.values(state.categories)
		.filter(c => {
			if (!c) {
				return false;
			}

			return c.filials.hasResults();
		})
		.sort((a, b) => (a?.position || 0) - (b?.position || 0));
});

const clearFilials = () => {
	state.filials.clearResults();
	state.categories = {};
};

export const BrandsService = {
	state: readonly(state) as IState,
	categories,
	fetchFilials,
	fetchSubFilials,
	fetchFilialLink,
	fetchFilialsByBrand: getFilialsByBrand,
	clearFilials,
	loadMoreFilials,
	fetchCategories
};
