import { isValidApiData } from '@/utils/functions';

interface ApiProxyResponseMeta {
	current_page: number;
	from: number;
	last_page: number;
	links: any;
	path: string;
	per_page: number;
	to: number;
	total: number;
}

interface ApiProxyResponse<T> {
	data: T;
	error_code: number;
	message: string;
	status: string;
	meta?: ApiProxyResponseMeta;
	links?: unknown;
}

interface ApiProxyJSONApiResponse<T> {
	attributes: T;
	id: string;
	type: string;
}

const isApiProxyResponse = <T>(
	data: ApiProxyResponse<T> | unknown
): data is ApiProxyResponse<T> => isValidApiData(data, 'error_code');

export {
	ApiProxyResponse,
	ApiProxyResponseMeta,
	isApiProxyResponse,
	ApiProxyJSONApiResponse
};
