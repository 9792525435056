import { client } from '../../default.client';

/**
 * Выставить язык пользователя
 */
export default function (language: string): Promise<void> {
	return client
		.post('profile/api/v1/profile/language', {
			language
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}
