import { IProduct, IProductsCategory } from '@/types/interfaces/menu';

export default class ProductsCategory implements IProductsCategory {
	id: number;
	type = 'categories';
	attributes: { name: string; is_active: boolean; is_show_photo: boolean };
	relationships: IProduct[] = [];

	constructor(id: number, name: string) {
		this.id = id;
		this.attributes = { name, is_active: true, is_show_photo: true };
	}

	/**
	 * Получение объекта категории
	 */
	getCategory(): IProductsCategory {
		return {
			id: this.id,
			type: this.type,
			attributes: this.attributes,
			relationships: this.relationships
		};
	}

	/**
	 * Добавление продукта в категорию
	 */
	addProduct(product: IProduct) {
		this.relationships.push(product);
	}

	/**
	 * Добавление списка продуктов в категорию
	 */
	addProducts(products: IProduct[]) {
		this.relationships = this.relationships.concat(products);
	}

	/**
	 * Содержит ли категория продукты
	 * @returns {boolean}
	 */
	hasProducts(): boolean {
		return !!this.relationships.length;
	}

	/**
	 * Содержит ли категория определенный продукт
	 * @returns {boolean}
	 */
	hasProductById(id: number): boolean {
		return this.relationships.some(p => p.id === id);
	}
}
