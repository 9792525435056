export interface IImageProxyOptions {
	url: string;
	size?: string;
	lazy?: boolean;
	as?: string;
	placeholder?: string;
}

export const defaultPreset: IImageProxyOptions = {
	url: '',
	size: undefined,
	lazy: true,
	as: '',
	placeholder: ''
};
