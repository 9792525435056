<template>
	<Component
		:is="href ? 'a' : 'button'"
		:href="href"
		class="ui-btn relative block w-full overflow-hidden text-center text-lg font-black isolate"
		:class="[
			size === 'base' ? '' : `ui-btn--${size}`,
			`ui-btn--${type}`,
			{ 'pointer-events-none': disabled || loading }
		]"
		:style="minWidth ? { minWidth: `${minWidth}px` } : {}"
	>
		<span
			class="ui-btn__content flex items-center justify-center h-full"
			:class="{ 'opacity-50': disabled }"
			:style="contentPadding ? { padding: contentPadding } : {}"
		>
			<span
				class="ui-btn__inner block w-full"
				:style="loading ? { opacity: 0 } : {}"
			>
				<slot></slot>
			</span>

			<Transition name="fade">
				<span v-if="loading" class="absolute-transform-50">
					<UiLoader size="md" :color="loaderColor" />
				</span>
			</Transition>
		</span>
	</Component>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import UiLoader from './UiLoader.vue';

interface Props {
	type?: 'white' | 'light' | 'dark' | 'primary' | 'error';
	size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl';
	minWidth?: number;
	contentPadding?: string;
	disabled?: boolean;
	loading?: boolean;
	href?: string;
}

const props = withDefaults(defineProps<Props>(), {
	type: 'light',
	size: 'base',
	minWidth: 0,
	contentPadding: '',
	disabled: false,
	loading: false,
	href: ''
});

const loaderColor = computed(() => {
	return props.type === 'white' ? 'var(--primary-color)' : '#fff';
});
</script>

<style lang="scss" scoped>
.ui-btn {
	border-radius: 0.75rem;
	background: #fff;

	&--xs &__content {
		padding: 0.25rem;
		font-size: 0.875rem;
		font-weight: 700;
	}

	&--sm &__content {
		min-height: 2.25rem;
		padding: 0.5rem;
		font-size: 1rem;
		font-weight: 400;
	}

	&--lg &__content {
		min-height: 3.25rem;
	}

	&--xl &__content {
		min-height: 4rem;
	}

	&--lg,
	&--xl {
		border-radius: 1rem;
	}

	&--lg &__content,
	&--xl &__content {
		padding: 0.875rem 0.75rem;
		font-size: 1.5rem;
	}

	&__content {
		min-height: 2.75rem;
		padding: 0.875rem 0.75rem;
		font-size: 1.125rem;
	}

	&--white &__content {
		background-color: #fff;
	}

	&--light &__content {
		background-color: #f1f2f7;
	}

	&--dark &__content {
		color: #fff;
		background-color: #000;
	}

	&--primary &__content {
		color: #fff;
		background-color: var(--primary-color);
	}

	&--error &__content {
		color: #fff;
		background-color: #ec5555;
	}

	&__inner {
		transition: 0.25s;
	}
}
</style>
