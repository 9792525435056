import { client } from '../../default.client';
import { JSON_API_HEADER } from '../../constants';
import { ApiProxyResponse } from '../../types/interfaces/api-proxy-response-data';
import { IProductForApi } from '@/types/interfaces/orders';

interface Response {
	cooking_time?: number;
	is_high_cooking_time: boolean;
}

/**
 * Возвращает время приготовления переданных продуктов и статус повышенного времени приготовления
 */
export default function (
	filialId: number,
	products: IProductForApi[]
): Promise<ApiProxyResponse<Response>> {
	return client
		.post(
			'takeaway/order/cooking_time',
			{ filial_id: filialId, products },
			{ headers: JSON_API_HEADER }
		)
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}
