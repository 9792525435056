<template>
	<button
		class="location-icon flex-shrink-0 w-6 h-6 bg-center bg-no-repeat bg-contain"
		@click.stop="goToMap"
	></button>
</template>

<script>
import { useRouter } from 'vue-router';
import { ClientService } from '@/services/client';

/**
 * Кнопка, которая переходит на страницу карты
 * @module components/map/MapButtonLink
 */
export default {
	name: 'MapButtonLink',
	setup() {
		const router = useRouter();

		/**
		 * Переход к карте, если координаты доступны
		 */
		const goToMap = () => {
			ClientService.doIfCoordinatesExist(() => router.push({ name: 'Map' }));
		};

		return {
			goToMap
		};
	}
};
</script>
