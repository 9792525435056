import { IDiProvider } from './types';

const proxyHandler = {
	get(target: any, prop: string) {
		if (prop in target) {
			if (typeof target[prop] === 'function') {
				return new Proxy(target[prop], {
					apply: (target, thisArg, argumentsList) => {
						return Reflect.apply(target, thisArg, argumentsList);
					}
				});
			}

			// @ts-ignore
			return Reflect.get(...arguments);
		}

		return () => {
			console.warn(
				`${prop} is not exists, object has only: ${Object.keys(target)}`
			);
		};
	}
};

const pullValue = (value: IDiProvider['value']) => {
	if (typeof value === 'function') {
		return value();
	}

	return value;
};

export const getProviderValue = ({ value }: IDiProvider) => {
	return new Proxy(pullValue(value), proxyHandler);
};
