<template>
	<Transition name="fade">
		<div
			v-if="count"
			class="fixed z-100 top-0 right-0 bottom-0 left-0 flex items-center justify-center"
		>
			<UiLoader size="lg" box />
		</div>
	</Transition>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import UiLoader from './UiLoader.vue';

const count = ref(0);

/**
 * Показать лоадер
 */
const on = () => {
	count.value++;
};

/**
 * Скрыть лоадер
 */
const off = () => {
	if (count.value) {
		count.value--;
	}
};

/**
 * Показать лоадер, пока выполняется задача
 */
const handle = (task: () => Promise<unknown>) => {
	on();
	task().finally(off);
};

defineExpose({
	on,
	off,
	handle
});
</script>
