import { ClientService } from '@/services/client';
import { AppCustomConfig } from '@/types/interfaces/app';
import providers from '@/utils/plugins/di';

const config: AppCustomConfig | undefined = providers.application.getCustomConfig(); // prettier-ignore
const visibleServiceIdList = config && config.services;

const isServiceAvailable = (id: string): boolean => {
	if (!visibleServiceIdList) {
		return true;
	}

	return !!visibleServiceIdList.find(el => el === id);
};

const getDefaultServiceId = () => {
	let initialId = ClientService.state.service.id;

	if (visibleServiceIdList && !isServiceAvailable(initialId)) {
		initialId = visibleServiceIdList[0];
	}

	return initialId;
};

export const AppService = {
	visibleServiceIdList,
	isServiceAvailable,
	getDefaultServiceId
};
