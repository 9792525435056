import { InjectionKey, inject } from 'vue';

export default function <T>(key: InjectionKey<T>, fallback?: T) {
	const resolved = inject(key, fallback);
	if (!resolved) {
		throw new Error(`Could not resolve injection ${key.description}`);
	}

	return resolved;
}
