import { client } from '../../default.client';

const getBinary = (file: File): Promise<ArrayBuffer> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = () => {
			resolve(reader.result as ArrayBuffer);
		};

		reader.onerror = reject;

		reader.readAsArrayBuffer(file);
	});
};

export default async function (image: File): Promise<void> {
	const binaryImage = await getBinary(image);
	return client
		.post(`api/v3/user/avatar`, binaryImage, {
			headers: {
				'Content-Type': image.type
			},
			transformRequest: [data => data]
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}
