import { ICategory } from '@/types/interfaces/navigator';

export const CATEGORY_HASH: Record<number, ICategory | undefined> = {
	132: {
		name: 'bakery',
		value: 'categories.bakeryValue',
		title: 'categories.bakeryTitle',
		position: 1,
		image:
			'https://rahmet.object.pscloud.io/static-files/assets/takeaway/img/bakery.png'
	},
	133: {
		name: 'semifinished',
		value: 'categories.semiFinishedValue',
		title: 'categories.semiFinishedTitle',
		position: 2,
		image:
			'https://rahmet.object.pscloud.io/static-files/assets/takeaway/img/semifinished.png'
	},
	134: {
		name: 'ready',
		value: 'categories.readyValue',
		title: 'categories.readyTitle',
		position: 3,
		image:
			'https://rahmet.object.pscloud.io/static-files/assets/takeaway/img/ready.png'
	}
};
