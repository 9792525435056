import { ICategory } from '@/types/interfaces/navigator';

export default {
	name: 'bahandi',
	value: 'categories.bahandiTitle',
	title: 'categories.bahandiTitle',
	position: 5,
	image:
		'https://rahmet.object.pscloud.io/static-files/assets/takeaway/img/bahandi.png',
	payload: {
		search: 'Bahandi'
	}
} as ICategory;
