import { DirectiveBinding } from 'vue';

export default {
	beforeMount(el: HTMLInputElement, binding: DirectiveBinding) {
		const input = el.tagName === 'INPUT' ? el : el.querySelector('input');
		const isStrict = !!binding.value;

		if (input) {
			input.addEventListener('keypress', event => {
				const keyCode = event.keyCode ? event.keyCode : event.which;
				const target = event.target as HTMLInputElement;
				const currentValue = target.value;
				const hasDot = currentValue.includes('.');

				if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
					event.preventDefault();
				}

				if (keyCode === 46 && hasDot && !isStrict) {
					event.preventDefault();
				}
			});
		}
	}
};
