type CbType = (...args: any[]) => any;

/**
 * Замедленный вызов функции
 * @param {function} cb - Функция
 * @param {number} [timeout=300] - Время до вызова функции
 * @param {boolean} [immediate=false] - Срочный вызов
 */
export default function (cb: CbType, timeout = 300, immediate = false): CbType {
	let timer: ReturnType<typeof setInterval> | null = null;

	return function () {
		// @ts-ignore
		const context = this;
		const args = arguments;
		const callNow = immediate && !timer;

		if (timer) {
			clearTimeout(timer);
		}

		timer = setTimeout(function () {
			timer = null;

			if (!immediate) {
				// @ts-ignore
				cb.apply(context, args);
			}
		}, timeout);

		if (callNow) {
			// @ts-ignore
			cb.apply(context, args);
		}
	};
}
