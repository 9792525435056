<template>
	<div v-if="isComponentVisible()" class="px-4">
		<UiSwitcher
			:default-id="defaultServiceId"
			:delay="300"
			@select="(id: string) => $emit('select', id)"
		>
			<!-- Delivery -->
			<DeliveryItem
				v-if="!isWebView && isServiceAvailable(SERVICES.delivery.id)"
				:id="SERVICES.delivery.id"
			>
				{{ $t(SERVICES.delivery.name) }}
			</DeliveryItem>

			<!-- Express -->
			<ExpressItem
				v-if="isServiceAvailable(SERVICES.express.id)"
				:id="SERVICES.express.id"
			>
				{{ $t(SERVICES.express.name) }}
			</ExpressItem>

			<!-- Takeaway -->
			<TakeawayItem
				v-if="isServiceAvailable(SERVICES.takeaway.id)"
				:id="SERVICES.takeaway.id"
			>
				{{ $t(SERVICES.takeaway.name) }}
			</TakeawayItem>
		</UiSwitcher>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { UiSwitcher, UiSwitcherItem } from '@/components/ui/UiSwitcher';
import { AppService } from '@/services/app';
import { injectStrict } from '@/utils/functions';
import { SERVICES } from '@/utils/constants';
import { ProvidersKey } from '@/types/symbols';
import { withIconSwitcherItem } from '../hoc';

export default defineComponent({
	name: 'BaseServiceSwitcher',
	components: {
		UiSwitcher,
		TakeawayItem: withIconSwitcherItem(UiSwitcherItem, SERVICES.takeaway.id),
		ExpressItem: withIconSwitcherItem(UiSwitcherItem, SERVICES.express.id),
		DeliveryItem: withIconSwitcherItem(UiSwitcherItem, SERVICES.delivery.id)
	},
	props: {
		defaultService: {
			type: String,
			default: undefined
		},
		allowAll: {
			type: Boolean,
			default: false
		}
	},
	emits: ['select'],
	setup(props) {
		const providers = injectStrict(ProvidersKey);
		const isWebView = providers.application.isWebView();
		const defaultServiceId =
			props.defaultService || AppService.getDefaultServiceId();
		const visibleServiceIdList = AppService.visibleServiceIdList;

		const isServiceAvailable = (id: string) => {
			if (props.allowAll) {
				return true;
			}

			return AppService.isServiceAvailable(id);
		};

		const isComponentVisible = () => {
			if (visibleServiceIdList) {
				return visibleServiceIdList.length > 1;
			}

			return true;
		};

		return {
			defaultServiceId,
			isWebView,
			visibleServiceIdList,
			isServiceAvailable,
			isComponentVisible,
			SERVICES
		};
	}
});
</script>
