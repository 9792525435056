import { BillPreorderPayload, IPreorder } from '@/services/bill/types';
import { client } from '../../default.client';
import { ApiProxyResponse } from '../../types/interfaces';

export default function (
	payload: BillPreorderPayload
): Promise<ApiProxyResponse<IPreorder>> {
	return client
		.post('smart-receipt/v1/receipt/preorder', payload)
		.then(({ data }) => data)
		.catch(error => {
			throw error;
		});
}
