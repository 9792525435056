import { ITariffsPayload, IDeliveryTariff } from '@/types/interfaces/delivery';
import { client } from '../../default.client';
import { DELIVERY_TARIFFS } from '@/types/enums/delivery';

const getMostRelevantTariff = (response: IDeliveryTariff[]) => {
	const fastest = response.filter(tariff => tariff.is_fastest);
	return fastest.length ? fastest : response;
};

export default function (payload: ITariffsPayload): Promise<IDeliveryTariff[]> {
	return client
		.post('dr-delivery/api/v1/tariffs', payload)
		.then(response =>
			getMostRelevantTariff(response.data.data).sort(
				a => +(a.transport_type === DELIVERY_TARIFFS.FOOT)
			)
		)
		.catch(() => []);
}
