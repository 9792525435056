export default class QrPayPlugin {
	track_id: string;
	paymentUrl: string;
	environment: string;
	sucessUrl: string;
	failureUrl: string;
	lang: string;
	url: string =
		import.meta.env.VITE_ENV === 'production'
			? 'https://qr-pay.choco.kz'
			: 'https://qr-pay.chocodev.kz';

	constructor(args: {
		track_id: string;
		paymentUrl: string;
		environment: string;
		sucessUrl: string;
		failureUrl: string;
		lang: string;
	}) {
		const { paymentUrl, track_id, environment, sucessUrl, failureUrl, lang } =
			args;

		this.track_id = track_id;
		this.environment = environment;
		this.paymentUrl = paymentUrl;
		this.failureUrl = failureUrl;
		this.sucessUrl = sucessUrl;
		this.lang = lang;
	}

	initPayment() {
		const _url = new URL(this.url);
		_url.searchParams.set('track_id', this.track_id);
		_url.searchParams.set('paymentUrl', this.paymentUrl);
		_url.searchParams.set('environment', this.environment);
		_url.searchParams.set('sucessUrl', this.sucessUrl);
		_url.searchParams.set('failureUrl', this.failureUrl);
		_url.searchParams.set('displayType', 'kaspi');
		_url.searchParams.set('language', this.lang);

		window.location.href = _url.href;
	}
}
