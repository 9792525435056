import { VendorAnalyticsConfig } from '@/services/vendor-analytics/types';

export enum HapticFeedbackTypes {
	impactLight = 'impactLight',
	impactMedium = 'impactMedium',
	impactHeavy = 'impactHeavy',
	rigid = 'rigid',
	soft = 'soft',
	notificationSuccess = 'notificationSuccess',
	notificationWarning = 'notificationWarning',
	notificationError = 'notificationError',
	selection = 'selection' // this one is ios only
}
export interface AppCustomConfig {
	services: string[];
	brand: string;
	brand_id: string;
	color?: string;
	town?: string | string[];
	appStore?: string;
	discounts?: {
		images: string[];
		buttonImages?: [string, string];
	};
	gradingFilialId?: number;
	booking?: boolean;
	is_menu_button_icons_raw?: boolean;
	is_chocobalance_hidden?: boolean;
	category_ids?: number[];
	analytics?: VendorAnalyticsConfig;
	version?: string;
	openQRScannerWithTextResult?: () => void;
	changeLanguage?: (lang: string) => void;
	setUserID?: (userID: string) => void;
	clearUserID?: () => void;
	haptic?: (type: HapticFeedbackTypes) => void;
}

export interface AppWebHandler {
	didBecomeActive: () => void;
	didScanQrText: (text: string) => string | void;
	onCameraPermissionDenied: (text: string) => void;
	onBackPressed: () => boolean | void;
}
