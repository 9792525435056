import { ICartPosition } from '../types';
import { getFormattedAdditives } from './get-formatted-additives';

interface ITempCartPosition {
	product_id: number;
	additives: number[];
	promo_price?: number;
}

/**
 * Сравнение позиций в корзине
 */
export default function (
	first: ICartPosition,
	second: ICartPosition,
	checkForPromo = true
): boolean {
	const firstItem: ITempCartPosition = {
		product_id: first.product_id,
		additives: getFormattedAdditives(first.additive_groups)
			.map(a => a.id)
			.sort((a, b) => a - b)
	};

	const secondItem: ITempCartPosition = {
		product_id: second.product_id,
		additives: getFormattedAdditives(second.additive_groups)
			.map(a => a.id)
			.sort((a, b) => a - b)
	};

	if (checkForPromo) {
		firstItem.promo_price = first.promo_price;
		secondItem.promo_price = second.promo_price;
	}

	return JSON.stringify(firstItem) === JSON.stringify(secondItem);
}
