import { WeekdayType } from '@/types';

type IWeekDays = {
	[key in WeekdayType]: number;
};

export const WEEK_DAYS: IWeekDays = {
	Mon: 1,
	Tue: 2,
	Wed: 3,
	Thu: 4,
	Fri: 5,
	Sat: 6,
	Sun: 7
};
