import { client } from '../../default.client';
import { getAddressAdapter } from '../../adapters/user';
import { ApiProxyJSONApiResponse } from '../../types/interfaces';
import { ICfUserAddress } from '@/types/interfaces/chocofood';
import { IUserAddress } from '@/types/interfaces/user';

/**
 * Выдача адресов юзера
 */
export default function (): Promise<ICfUserAddress[]> {
	return client
		.get('profile/api/v1/address')
		.then(response =>
			response.data.data.map((el: ApiProxyJSONApiResponse<IUserAddress>) =>
				getAddressAdapter(el)
			)
		)
		.catch(error => {
			throw error;
		});
}
