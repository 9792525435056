import { ICategory } from '@/types/interfaces/navigator';

export default {
	name: 'recent',
	value: 'categories.recentValue',
	title: 'categories.recentTitle',
	position: 2,
	payload: {
		url: 'repeat',
		limit: 2
	}
} as ICategory;
