<template>
	<div
		class="fixed z-9999 top-0 right-0 left-0 flex p-4 viewport-max-width font-sirius pointer-events-none"
		:class="reversed ? 'flex-col-reverse bottom-12' : 'flex-col bottom-0'"
	>
		<TransitionGroup :name="transition">
			<div
				v-for="item in notifications"
				:key="item.key"
				class="notification flex flex-col last:mb-0 px-4 py-4 rounded-3xl pointer-events-auto base-shadow"
				:class="{
					[`notification--${item.type}`]: item.type,
					'notification--shake': item.shakeable && isShaking,
					'mb-2': !reversed,
					'mt-2': reversed
				}"
				:style="{
					background: item.opacity ? `rgba(0, 0, 0, ${item.opacity || 1})` : ''
				}"
				@click="remove(item)"
			>
				<div class="flex flex-1 items-center justify-between">
					<div>
						<p v-if="item.title" class="mb-1 text-lg font-black">
							{{ item.title }}
						</p>
						<p class="text-base">{{ item.text }}</p>
					</div>
					<span
						v-if="item.icon"
						class="w-9 h-9 flex-shrink-0 ml-2 rounded-xl"
						:style="{ background: `url('${item.icon}')` }"
					></span>
				</div>

				<button
					v-if="item.action"
					class="notification__btn block w-full mt-3 pt-2 px-2 pb-2.5 text-base font-medium rounded-xl"
					@click="item.action.callback"
				>
					{{ item.action.text }}
				</button>
			</div>
		</TransitionGroup>
	</div>
</template>

<script setup lang="ts">
import { ref, onUnmounted } from 'vue';
import { INFINITE } from './constants';
import successIcon from '@/assets/icons/success-mark.svg';
import errorIcon from '@/assets/icons/error-mark.svg';
import {
	INotificationAction,
	INotificationElement,
	INotificationOptions
} from '@/types/interfaces/common/notification';

interface Props {
	transition?: string;
	duration?: number;
	reversed?: boolean;
	onlyUnique?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	transition: 'slide-fade',
	duration: 3000,
	reversed: false,
	onlyUnique: false
});

const notifications = ref<INotificationElement[]>([]);
const isShaking = ref(false);
let timer: NodeJS.Timeout | null = null;

const remove = (notification: INotificationElement) => {
	const i = notifications.value.indexOf(notification);

	if (i >= 0) {
		notifications.value.splice(i, 1);
	}
};

const removeAll = () => {
	notifications.value = [];
};

const getUniqueNotification = (notification: INotificationElement) => {
	if (!props.onlyUnique) {
		return;
	}

	return notifications.value.find(item => item.text === notification.text);
};

/**
 * Создание уведомления
 * @param {object|string} message - Сообщение или параметры сообщения
 * @param {number} duration - Длительность
 * @param {notificationAction|undefined} action - Параметры действия
 */
const show = (
	message: INotificationOptions,
	duration = props.duration,
	action?: INotificationAction
) => {
	let type = '';
	let icon = '';
	let title = '';
	let text = '';

	if (typeof message === 'object') {
		type = message.type || '';
		icon = message.icon || '';
		title = message.title || '';
		text = message.text || '';
	} else {
		text = message;
	}

	const notification = {
		key: `${Date.now()}-${Math.random()}`,
		action,
		type,
		icon,
		text,
		title,
		opacity: message?.opacity,
		shakeable: message?.shakeable
	};

	const unique = getUniqueNotification(notification);
	if (unique) {
		return unique;
	}

	notifications.value.push(notification);

	if (duration !== INFINITE) {
		setTimeout(() => remove(notification), duration);
	}

	return notification;
};

const success = (
	text: string,
	title?: string,
	duration = props.duration,
	action?: INotificationAction
) => {
	const options = {
		type: 'success',
		icon: successIcon,
		title,
		text
	};

	return show(options, duration, action);
};

/**
 * Создание уведомления
 * @param {string} text - Сообщение
 * @param {string} title - Заголовок
 * @param {number} duration - Длительность
 * @param {notificationAction|undefined} action - Параметры действия
 */
const error = (
	text: string,
	title?: string,
	duration = props.duration,
	action?: INotificationAction
) => {
	const options = {
		type: 'error',
		icon: errorIcon,
		title,
		text
	};

	return show(options, duration, action);
};

const shake = (duration = 300) => {
	isShaking.value = true;

	if (timer) {
		clearTimeout(timer);
	}

	timer = setTimeout(() => {
		isShaking.value = false;
	}, duration);
};

onUnmounted(() => {
	if (timer) {
		clearTimeout(timer);
	}
});

defineExpose({
	show,
	success,
	error,
	remove,
	removeAll,
	shake
});
</script>

<style lang="scss" scoped>
.notification {
	min-height: 68px;
	color: #fff;
	background: #000;

	&__btn {
		color: #000;
		background: #fff;
	}

	&--success {
		color: #000;
		background: #f1f2f7;

		.notification__btn {
			background: #d4d3df;
		}
	}

	&--shake {
		animation: shake 0.3s infinite;
	}
}

@keyframes shake {
	0% {
		transform: translateX(0);
	}
	25% {
		transform: translateX(-10px);
	}
	50% {
		transform: translateX(5px);
	}
	75% {
		transform: translateX(-5px);
	}
	100% {
		transform: translateX(5px);
	}
}
</style>
