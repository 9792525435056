import { ICfUserAddress } from '@/types/interfaces/chocofood';
import { client } from '../../default.client';
import { getAddressAdapter } from '../../adapters/user';

/**
 * Изменение адреса по id
 */
export default function (payload: ICfUserAddress): Promise<ICfUserAddress> {
	return client
		.put(`profile/api/v1/address/${payload.id}`, payload)
		.then(response => getAddressAdapter(response.data.data))
		.catch(error => {
			throw error;
		});
}
