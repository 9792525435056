/**
 * Получение ключа объекта по значению
 * @module utils/functions/get-key-by-value
 * @param {object} obj - Объект
 * @param {any} value - Значение в объекте
 * @returns {string}
 */

interface IObject {
	[key: string]: unknown;
}

export default function (obj: IObject, value: unknown) {
	return Object.keys(obj).find(key => obj[key] === value);
}
