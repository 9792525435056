import { init, track, setUserId, add } from '@amplitude/analytics-browser';
import { logAmplitudeEvent } from 'choco-app';
import providers from '@/utils/plugins/di';
import { ANALYTICS_INSTANCES } from './enums';

const isWebView = providers.application.isWebView();

abstract class AnalyticsCommon<EVENTS> {
	private key: string;
	name: string;
	isLoggable: boolean;
	isDev: boolean;

	constructor(key: string, name: string) {
		this.key = key;
		this.isLoggable = import.meta.env.VITE_ENV !== 'production';
		this.isDev = import.meta.env.VITE_ENV === 'local';
		this.name = name;
	}

	async init() {
		const { sessionReplayPlugin } = await import(
			'@amplitude/plugin-session-replay-browser'
		);

		init(this.key, { instanceName: this.name, autocapture: false });
		add(sessionReplayPlugin({ sampleRate: 0.2 }));
	}

	protected log(event: string, data: any, userId?: string) {
		// логировать события не в production
		if (this.isLoggable) {
			console.log(event, data);
		}

		// отправлять события не в режиме разработки
		if (this.isDev) {
			return;
		}

		// использовать в вебвью нативный метод аналитики
		if (this.name === ANALYTICS_INSTANCES.TAKEAWAY && isWebView) {
			return logAmplitudeEvent(event, data);
		}

		setUserId(userId);
		track(event, data);
	}

	abstract logEvent(event: EVENTS, payload: any): Promise<void>;
}

export { AnalyticsCommon };
