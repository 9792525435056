import { LANG_STORAGE_KEY } from './constants';
import { getQueryByName } from '@/utils/functions';

/**
 * Возвращает язык, который будет использовать при запуске приложения
 * @returns {string} - язык приложения
 */
export default function () {
	const queryLanguage = getQueryByName('language');
	if (queryLanguage) {
		return queryLanguage;
	}

	const savedLocale = localStorage.getItem(LANG_STORAGE_KEY);
	if (savedLocale) {
		return savedLocale;
	}

	return 'kk';
}
