import { client } from '../../default.client';
import { ApiProxyResponse } from '../../types/interfaces';
import { IFilialToken } from '@/services/bill/types';

/**
 * Получение filial token
 */
export default async function (filialId: string | number) {
	return await client
		.get<ApiProxyResponse<IFilialToken>>(`partner/filial/pure/${filialId}`)
		.then(res => res.data)
		.catch(error => {
			throw error;
		});
}
