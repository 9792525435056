import { RouteLocationNormalizedLoaded, NavigationGuardNext } from 'vue-router';
import { MenuService } from '@/services/menu';

export default function (
	to: RouteLocationNormalizedLoaded,
	from: RouteLocationNormalizedLoaded,
	next: NavigationGuardNext
) {
	MenuService.clearTempTerminals();
	next();
}
