import { WebApplication } from './web-application.provider';
import { SERVICES, VISIBLE_TOWNS, NATIVE_APP_AGENT } from '@/utils/constants';
import { APP_RUNTIME } from '@/types/enums/common';
import { AppCustomConfig, HapticFeedbackTypes } from '@/types/interfaces/app';
import { IService } from '@/types/interfaces/common';
import { ITown } from '@/types/interfaces/common/address';
import { TCallback } from '@/utils/plugins/di/types';

if (window.ReactNativeWebView && !window.ReactNativeWeb) {
	window.ReactNativeWeb = {
		didBecomeActive: () => {},
		didScanQrText: () => {},
		onCameraPermissionDenied: () => {},
		onBackPressed: () => {}
	};
}

const backHandlersStack: Array<Function> = [];

class ReactNativeApplication extends WebApplication {
	constructor(runtimeName: APP_RUNTIME) {
		super(runtimeName);
	}

	private connect = <T>(callback: Function): Promise<T> => {
		return new Promise<T>((resolve, reject) => {
			try {
				callback(resolve, reject);
			} catch (error) {
				reject(error);
			}
		});
	};

	init() {
		const config = this.getCustomConfig();
		if (config && config.color) {
			document.documentElement.style.setProperty(
				'--primary-color',
				config.color
			);
		}
	}

	private filterValue(value?: string): string | undefined {
		if (value && value !== 'undefined') {
			return value;
		}
	}

	getCustomConfig(): AppCustomConfig | undefined {
		if (window.ReactNativeWebView) {
			return {
				...window.ReactNativeWebView,
				color: this.filterValue(window.ReactNativeWebView.color)
			};
		}
	}

	becomingActive(cb: TCallback) {
		// TODO: отделить ReactNativeWebView и Customization
		if (
			navigator.userAgent.includes(NATIVE_APP_AGENT) &&
			window.ReactNativeWeb
		) {
			window.ReactNativeWeb.didBecomeActive = cb;
		} else {
			document.addEventListener('visibilitychange', () => {
				this.onVisibilityChange(cb);
			});
		}
	}

	becomingHidden() {
		// TODO: отделить ReactNativeWebView и Customization
		if (
			navigator.userAgent.includes(NATIVE_APP_AGENT) &&
			window.ReactNativeWeb
		) {
			window.ReactNativeWeb.didBecomeActive = () => {};
		} else {
			document.removeEventListener(
				'visibilitychange',
				this.onVisibilityChange as any
			);
		}
	}

	handleNavigationToBack() {
		if (!window.ReactNativeWeb) {
			return false;
		}

		window.ReactNativeWeb.onBackPressed = function () {
			const responseToNative = backHandlersStack.length > 0;
			if (responseToNative) {
				const cb = backHandlersStack[backHandlersStack.length - 1];
				cb();
			}

			return responseToNative;
		};
	}

	whenNavigationToBack(cb: TCallback) {
		backHandlersStack.push(cb);
	}

	cancelHandlingToBack() {
		backHandlersStack.pop();
	}

	getServiceById(id: string, strict = false): IService {
		let result: IService | undefined;
		let config: AppCustomConfig | undefined;

		if (strict) {
			result = this.findServiceById(id);
		} else {
			config = this.getCustomConfig();
		}

		if (config) {
			if (config.services.includes(id)) {
				result = this.findServiceById(id);
			} else {
				if (config.services.includes(SERVICES.delivery.id)) {
					return SERVICES.delivery;
				}

				result = this.findServiceById(config.services[0]);
			}
		}

		return result || SERVICES.takeaway;
	}

	getVisibleTowns(): ITown[] {
		const config = this.getCustomConfig();
		let towns = VISIBLE_TOWNS;

		if (!config) {
			return towns;
		}

		if (!config.town) {
			return towns;
		}

		if (Array.isArray(config.town)) {
			towns = towns.filter(t => config.town && config.town.includes(t.title));
		} else {
			towns = towns.filter(t => config.town === t.title);
		}

		return towns;
	}

	openQR = (): Promise<string> => {
		return this.connect(
			(resolve: (text: string) => string, reject: (error: Error) => void) => {
				if (
					!window.ReactNativeWebView?.openQRScannerWithTextResult ||
					!window.ReactNativeWeb
				) {
					return resolve('');
				}

				window.ReactNativeWebView.openQRScannerWithTextResult();
				window.ReactNativeWeb.didScanQrText = text => resolve(text);
				window.ReactNativeWeb.onCameraPermissionDenied = text => {
					reject(new Error(text));
				};
			}
		);
	};

	changeLanguage = (lang: string): boolean => {
		if (!window.ReactNativeWebView?.changeLanguage) {
			return false;
		}

		window.ReactNativeWebView.changeLanguage(lang);
		return true;
	};

	setUserId = (id: string) => {
		if (window.ReactNativeWebView?.setUserID) {
			window.ReactNativeWebView.setUserID(id);
		}
	};

	clearUserId = () => {
		if (window.ReactNativeWebView?.clearUserID) {
			window.ReactNativeWebView.clearUserID();
		}
	};

	haptic = (type: HapticFeedbackTypes = HapticFeedbackTypes.impactLight) => {
		if (window.ReactNativeWebView?.haptic) {
			window.ReactNativeWebView.haptic(type);
		}
	};
}

export default new ReactNativeApplication(APP_RUNTIME.REACT_NATIVE);
