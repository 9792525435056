interface ITokenParsed {
	aud?: string;
	jti?: string;
	iat?: number;
	nbf?: number;
	exp?: number;
	sub?: string;
	scopes?: string[];
	scopes_str?: string;
	owner_id?: number;
}

/**
 * Парсинг JWT-токена
 */
export default function (token: string): ITokenParsed {
	try {
		return JSON.parse(atob(token.split('.')[1]));
	} catch {
		return {};
	}
}
