<template>
	<label class="ui-switcher-item w-full mr-2 last:mr-0 font-sirius">
		<input
			type="radio"
			:name="name"
			:data-id="id"
			:checked="isActive(id)"
			@click.stop="onClick(id)"
		/>

		<span
			class="ui-switcher-item__inner relative z-1 flex items-center justify-center w-full"
			:style="{
				minHeight: `${ISLAND_HEIGHT}px`
			}"
		>
			<slot name="icon"></slot>
			<slot></slot>
		</span>
	</label>
</template>

<script setup lang="ts">
import {
	SwitcherNameKey,
	SwitcherOnClickKey,
	SwitcherIsActiveKey
} from './symbols';
import { ISLAND_HEIGHT } from './constants';
import { injectStrict } from '@/utils/functions';

interface Props {
	id: string;
}
defineProps<Props>();

const name = injectStrict(SwitcherNameKey);
const onClick = injectStrict(SwitcherOnClickKey);
const isActive = injectStrict(SwitcherIsActiveKey);
</script>

<style lang="scss" scoped>
.ui-switcher-item {
	position: relative;

	&:last-child {
		margin-right: 0 !important;
	}

	input[type='radio'] {
		position: absolute;
		width: 0;
		height: 0;
		opacity: 0;
	}

	&__inner {
		transition: color 0.2s ease-in;
	}

	& input[type='radio']:checked ~ &__inner {
		color: #000;
	}
}
</style>
