import { client } from '../../default.client';
import { RyadomAddCartPayload } from '@/types/interfaces/ryadom';

const addToRyadomCart = (
	payload: RyadomAddCartPayload,
	deviceId: string
): Promise<void> => {
	return client
		.put('/cart/v1/guest/cart', payload, {
			headers: {
				'X-Device-Id': deviceId
			}
		})
		.then(res => res.data)
		.catch(error => {
			throw error;
		});
};

const addToRyadomCartAuth = (payload: RyadomAddCartPayload): Promise<void> => {
	return client
		.put('/cart/v1/cart', payload)
		.then(res => res.data)
		.catch(error => {
			throw error;
		});
};

export { addToRyadomCart, addToRyadomCartAuth };
