<template>
	<div class="flex flex-col">
		<!-- История -->
		<ul class="px-4 mb-3">
			<li
				v-for="(value, i) in values"
				:key="i"
				class="flex items-center justify-between py-3 border-b border-solid border-grey-lite"
				data-test-name="history-item"
				@click="$emit('select', value)"
			>
				<span class="font-sirius text-base font-medium">
					{{ value }}
				</span>
				<span
					class="history-arrow flex-shrink-0 w-6 h-6 bg-contain bg-no-repeat"
				></span>
			</li>
		</ul>

		<!-- Кнопка очистки -->
		<div class="px-4">
			<button
				class="flex items-center justify-center w-full h-9"
				data-testid="history-btn-clear"
				@click="$emit('clear')"
			>
				<span
					class="history-refresh flex-shrink-0 w-4 h-4 mr-2 bg-contain bg-no-repeat"
				></span>
				<span class="font-sirius text-base text-blue-accent">
					{{ $t('search.clearHistory') }}
				</span>
			</button>
		</div>
	</div>
</template>

<script>
/**
 * @module components/search/SearchHistory
 * @vue-prop {string[]} [values=[]] - Список значений в истории поиска
 * @vue-event {string} select - Передает значение, которое выбрали
 * @vue-event {undefined} clear - Вызывается при нажатии на кнопку очистки истории
 */
export default {
	name: 'SearchHistory',
	props: {
		values: {
			type: Array,
			default: () => []
		}
	},
	emits: ['select', 'clear']
};
</script>

<style lang="scss" scoped>
.history {
	&-arrow {
		background-image: url('@/assets/icons/arrow-up-left.svg');
	}

	&-refresh {
		background-image: url('@/assets/icons/refresh.svg');
	}
}
</style>
