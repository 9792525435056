import { reactive, readonly } from 'vue';
import { SEARCH_HISTORY_VALUE } from '../constants';
import {
	ISearchHistoryService,
	ISearchHistoryState
} from '@/types/interfaces/search';

const storage = window.localStorage;
const MAX_HISTORY_LENGTH = 3;

const state = reactive<ISearchHistoryState>({
	history: JSON.parse(storage.getItem(SEARCH_HISTORY_VALUE)!) || []
});

const addHistoryValue = (value: string): string => {
	if (!value || state.history.includes(value)) {
		return value;
	}

	let newHistory = [...state.history];
	newHistory.unshift(value);

	if (newHistory.length > MAX_HISTORY_LENGTH) {
		newHistory = newHistory.slice(0, MAX_HISTORY_LENGTH);
	}

	state.history = newHistory;
	storage.setItem(SEARCH_HISTORY_VALUE, JSON.stringify(newHistory));
	return value;
};

const clearHistory = (): void => {
	state.history = [];
	storage.removeItem(SEARCH_HISTORY_VALUE);
};

export const SearchHistory: ISearchHistoryService = {
	state: readonly(state) as ISearchHistoryState,
	addHistoryValue,
	clearHistory
};
