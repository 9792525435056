<template>
	<div class="balance-appearance flex items-center text-lg font-black">
		<div
			class="mr-2"
			:class="props.variant === 'default' ? 'text-red-lite' : 'text-white'"
		>
			<svg
				:width="props.size === 'lg' ? 28 : 22"
				:height="props.size === 'lg' ? 28 : 22"
				viewBox="0 0 22 22"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M21.6388 10.6716C15.9582 10.6716 11.3284 6.04179 11.3284 0.328358C11.3284 0.131343 11.197 0 11 0C10.803 0 10.6716 0.131343 10.6716 0.328358C10.6716 6.04179 6.04179 10.6716 0.328358 10.6716C0.131343 10.6716 0 10.803 0 11C0 11.197 0.131343 11.3284 0.328358 11.3284C6.04179 11.3284 10.6716 15.9582 10.6716 21.6716C10.6716 21.8687 10.803 22 11 22C11.197 22 11.3284 21.8687 11.3284 21.6716C11.3284 15.9582 15.9582 11.3284 21.6716 11.3284C21.8687 11.3284 22 11.197 22 11C22 10.803 21.8358 10.6716 21.6388 10.6716Z"
					fill="currentColor"
				/>
			</svg>
		</div>

		<div
			class="whitespace-nowrap"
			:class="{
				'text-sm': isLargeAmount,
				'text-white': props.variant === 'light',
				'text-black': props.variant === 'default'
			}"
		>
			{{ $thousandSeparator(amount) }}
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface Props {
	amount: number;
	variant?: 'default' | 'light';
	size?: 'base' | 'lg';
}

const props = withDefaults(defineProps<Props>(), {
	variant: 'default',
	size: 'base'
});

const isLargeAmount = computed(() => props.amount >= 100000);
</script>

<style lang="scss">
.balance-appearance {
	animation: appearance 0.3s forwards;
}

@keyframes appearance {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
</style>
