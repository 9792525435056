import { ref } from 'vue';

const isStoriesActive = ref(false);

function useStories() {
	const setIsStoriesActive = (value: boolean) => {
		isStoriesActive.value = value;
	};

	return {
		isStoriesActive,
		setIsStoriesActive
	};
}

export default useStories;
