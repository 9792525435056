/**
 * i18n pluralization function.
 * Получение индекса для склонения слова в зависимости от числа. Для русского языка.
 *
 * Если используете pluralization как выбор между несколькими вариантами,
 * Например: "плохо | круто | отлично"
 * То можно использовать так:
 * pluralizeRu(1) = 0 // плохо
 * pluralizeRu(2) = 1 // круто
 * pluralizeRu(0) = 2 // отлично
 */
function pluralizeRu(choice: number) {
	const cases = [2, 0, 1, 1, 1, 2];

	if (choice % 1 !== 0) {
		return cases[2];
	}

	return cases[Math.min(choice % 10, 5)];
}

export { pluralizeRu };
