import { DirectiveBinding } from 'vue';

export default {
	beforeMount(el: HTMLElement, binding: DirectiveBinding) {
		if ('clickOutsideEvent' in el) {
			el.clickOutsideEvent = (event: Event) => {
				if (!(el === event.target || el.contains(event.target as Node))) {
					binding.value(event, el);
				}
			};

			document.body.addEventListener(
				'click',
				el.clickOutsideEvent as EventListener
			);
		}
	},
	unmounted(el: HTMLElement) {
		if ('clickOutsideEvent' in el) {
			document.body.removeEventListener(
				'click',
				el.clickOutsideEvent as EventListener
			);
		}
	}
};
