import { RouteLocationNormalizedLoaded, NavigationGuardNext } from 'vue-router';

export default function (
	to: RouteLocationNormalizedLoaded,
	from: RouteLocationNormalizedLoaded,
	next: NavigationGuardNext
) {
	const newRoute = to.fullPath.replace('//', '/');

	if (to.fullPath !== newRoute) {
		return next(newRoute);
	}

	return next();
}
