import { PROMO_CATEGORY, FREQUENT_CATEGORY } from '../constants';
import i18n from '@/utils/plugins/i18n';
import { IProductsCategory } from '@/types/interfaces/menu';

const LOCAL_CATEGORY_HASH = {
	[PROMO_CATEGORY.id]: PROMO_CATEGORY.translateKey,
	[FREQUENT_CATEGORY.id]: FREQUENT_CATEGORY.translateKey
};

/**
 * Обновляет локальные названия категорий в меню
 */
export default function (menu: IProductsCategory[]) {
	menu.forEach(category => {
		const categoryId = category.id;
		if (LOCAL_CATEGORY_HASH[categoryId]) {
			category.attributes.name = i18n.global.t(LOCAL_CATEGORY_HASH[categoryId]);
		}
	});
}
