import { client } from '../../default.client';
import { JSON_API_HEADER } from '../../constants';
import { ApiProxyResponse } from '../../types/interfaces/api-proxy-response-data';
import { IFilialSettings } from '@/types/interfaces/navigator';

/**
 * Возвращает настройки филиала
 */
export default function (
	filialId: number,
	abort?: AbortController
): Promise<ApiProxyResponse<IFilialSettings>> {
	const url = `takeaway/v2/filial/settings/banner/info/${filialId}?is_web_view=1`;

	const config = {
		headers: JSON_API_HEADER,
		signal: abort?.signal
	};

	return client
		.get(url, { ...config })
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}
