const addGTMNoScript = (id: string) => {
	const noScript = document.createElement('noscript');
	const iframe = document.createElement('iframe');

	iframe.src = `https://www.googletagmanager.com/ns.html?id=${id}`;
	iframe.height = '0';
	iframe.width = '0';
	iframe.style.display = 'none';
	iframe.style.visibility = 'hidden';

	noScript.appendChild(iframe);
	document.body.appendChild(noScript);
};

const loadGTM = (id: string) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		'gtm.start': new Date().getTime(),
		event: 'gtm.js'
	});

	const script = document.createElement('script');
	const firstScript = document.getElementsByTagName('script')[0];

	script.async = true;
	script.src = `https://www.googletagmanager.com/gtm.js?id=${id}`;
	firstScript.parentNode?.insertBefore(script, firstScript);

	addGTMNoScript(id);
};

export default loadGTM;
