import { client } from '../../default.client';

interface IResponse {
	data: {
		id: string;
		type: string;
		attributes: {
			user_id: number;
			language: string;
		};
	};
}

/**
 * Получение данных профиля юзера. Вернет ошибку, если юзера нет
 */
export default function (): Promise<IResponse> {
	return client
		.get('profile/api/v1/profile')
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}
