import { client } from '../../default.client';
import { ApiProxyResponse } from '../../types/interfaces';
import { IReward } from '@/types/interfaces/loyalty';

export default function (amount: number, terminalId: number) {
	return client
		.get<ApiProxyResponse<IReward>>(`loyalty/reward-calculate/${amount}`, {
			params: {
				terminal_id: terminalId
			}
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}
