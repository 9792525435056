<template>
	<button class="ui-btn-close inline-block w-8 h-8">
		<span class="ui-btn-close__line inline-block bg-grey-dark"></span>
		<span class="ui-btn-close__line inline-block bg-grey-dark"></span>
	</button>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.ui-btn-close {
	position: relative;
	background: rgba(0, 0, 0, 0.05);
	border-radius: 0.625rem;

	&__line {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 2px;
		height: 18.5px;

		&:first-child {
			transform: translate(-50%, -50%) rotate(45deg);
		}

		&:last-child {
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}
}
</style>
