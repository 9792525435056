import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { IFilial } from '@/types/interfaces/navigator';
import { getWorkingHoursTextByWeekday } from './get-working-hours-text';

dayjs.extend(isoWeek);

/**
 * Получение текста статуса филиала
 */
export default function (filial: IFilial): string {
	if (filial.banner_info) {
		return filial.banner_info.message;
	}

	if (filial.schedule) {
		const weekday = dayjs().isoWeekday();
		return getWorkingHoursTextByWeekday(weekday, filial.schedule);
	}

	return '';
}
