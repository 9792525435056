import { RouteLocationNormalizedLoaded, NavigationGuardNext } from 'vue-router';
import { delivery } from '@/utils/plugins/delivery';
import providers from '@/utils/plugins/di';

export default function (
	to: RouteLocationNormalizedLoaded,
	from: RouteLocationNormalizedLoaded,
	next: NavigationGuardNext
) {
	if (delivery.contains()) {
		const deliveryService = delivery.service();

		if (!deliveryService.hasAvailableAddress()) {
			providers.application.showLoader();

			return delivery
				.service()
				.initUserAddresses()
				.then(() => next())
				.finally(() => providers.application.hideLoader());
		}
	}

	return next();
}
