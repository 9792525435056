import { AxiosResponse } from 'axios';
import { client } from '../../default.client';
import { getBillAdapter } from '../../adapters/bill';
import { IBill } from '@/services/bill/types';
import { IBillDetails } from '@/types/interfaces/orders';

export default function (
	tableId: number,
	terminalId: string
): Promise<IBillDetails> {
	return client
		.get<AxiosResponse<IBill>>(`smart-receipt/v1/receipt`, {
			params: {
				table_id: tableId,
				terminal_id: terminalId
			}
		})
		.then(response => {
			return getBillAdapter(response.data.data);
		})
		.catch(error => {
			throw error;
		});
}
