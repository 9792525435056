import { readonly } from 'vue';
import {
	ISearchHistoryService,
	ISearchHistoryState
} from '@/types/interfaces/search';

const state: ISearchHistoryState = {
	history: []
};

const addHistoryValue = (value: string): string => value;

const clearHistory = (): void => {
	state.history = [];
};

export const SearchHistoryEmpty: ISearchHistoryService = {
	state: readonly(state) as ISearchHistoryState,
	addHistoryValue,
	clearHistory
};
