import App from './App';
import { createApp } from 'vue';
import VueYandexMetrika from 'vue-v3-yandex-metrika';
import { Analytics } from '@/services/analytics';
import { SentryService } from '@/services/sentry';
import { VendorAnalytics } from '@/services/vendor-analytics';
import { clickOutside, onlyLetter, onlyNumber } from '@/utils/directives';
import { loaderPlugin } from '@/utils/plugins/loader';
import { notificationPlugin } from '@/utils/plugins/notification';
import { imageProxyPlugin } from '@/utils/plugins/image-proxy';
import { deliveryPlugin } from './utils/plugins/delivery';
import { DependencyInjectionPlugin } from '@/utils/plugins/di/plugin-installer';
import providers from '@/utils/plugins/di';
import { thousandSeparatorPlugin } from '@/utils/plugins/thousand-separator';
import router from './router';
import i18n from './utils/plugins/i18n';
import './assets/styles/styles.scss';

const app = createApp(App);
const appEnv = import.meta.env.VITE_ENV;

app.use(router);

if (appEnv === 'production') {
	app.use(VueYandexMetrika, {
		id: import.meta.env.VITE_YANDEX_KEY,
		env: appEnv,
		router,
		options: {
			clickmap: true,
			trackLinks: true,
			accurateTrackBounce: true,
			webvisor: true,
			triggerEvent: true
		}
	});
}

app.use(i18n);
app.use(loaderPlugin);
app.use(notificationPlugin);
app.use(imageProxyPlugin);
app.use(DependencyInjectionPlugin);
app.use(deliveryPlugin);
app.use(thousandSeparatorPlugin);

Analytics.init();

const config = providers.application.getCustomConfig();
if (config?.analytics) {
	VendorAnalytics.init(config.analytics);
}

SentryService.init(app, router);

app.directive('click-outside', clickOutside);
app.directive('only-number', onlyNumber);
app.directive('only-letter', onlyLetter);
app.mount('#app');
