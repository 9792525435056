import { App, Plugin } from 'vue';
import { thousandSeparator } from '@/utils/functions';
import { ThousandsSeparatorKey } from '@/types/symbols';

const thousandSeparatorPlugin: Plugin = {
	install(app: App) {
		app.provide(ThousandsSeparatorKey, thousandSeparator);
		app.config.globalProperties.$thousandSeparator = thousandSeparator;
	}
};

export { thousandSeparatorPlugin };
