<template>
	<Teleport to="body">
		<Transition name="fade">
			<div v-if="isActive" class="ui-modal-container">
				<!-- Контент -->
				<div class="ui-modal viewport-max-width">
					<slot></slot>
				</div>

				<!-- Оверлей -->
				<div class="ui-modal-overlay" @click="onOverlayClick"></div>
			</div>
		</Transition>
	</Teleport>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { injectStrict } from '@/utils/functions';
import { ProvidersKey } from '@/types/symbols';

interface Props {
	closeable?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	closeable: true
});

const isActive = ref(false);
const providers = injectStrict(ProvidersKey);

/**
 * Открыть модальное окно
 */
const open = () => {
	isActive.value = true;
	providers.application.handleNavigationToBack();
	providers.application.whenNavigationToBack(hide);
};

/**
 * Закрыть модальное окно
 */
const hide = () => {
	isActive.value = false;
	providers.application.cancelHandlingToBack();
};

const onOverlayClick = () => {
	if (props.closeable) {
		hide();
	}
};

defineExpose({
	isActive,
	open,
	hide
});
</script>

<style lang="scss" scoped>
@keyframes slide-with-opacity {
	0% {
		transform: translateY(-40px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

.ui-modal {
	width: 100%;
	overflow: auto;
	background: #fff;
	border-radius: 1.5rem;
	animation: 0.2s ease-out 0s 1 slide-with-opacity;

	&-container {
		position: fixed;
		z-index: 9999;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		padding: 1rem;
		background: transparent;
		backdrop-filter: blur(1rem);
	}

	&-overlay {
		position: fixed;
		z-index: -1;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(15, 15, 17, 0.15);
		backdrop-filter: blur(9px);
	}
}
</style>
