import { IScheduleItem } from '@/types/interfaces/common';
import getDayFromSchedule from './get-day-from-schedule';

/**
 * Получение графика работы первого и последнего дня
 */
export default function (weekday: number, schedule?: IScheduleItem[]) {
	if (!schedule || !schedule.length) {
		return null;
	}

	const daySchedule = getDayFromSchedule(weekday, schedule);
	const hoursList = daySchedule.opening_hours;

	if (!hoursList) {
		return null;
	}

	const first = hoursList[0];
	const last = hoursList[hoursList.length - 1];

	return { from: first.from, to: last.to };
}
