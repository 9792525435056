import { Ref, defineAsyncComponent } from 'vue';
import {
	IGradeLibraryInstance,
	initGrading,
	needDetermineGrading
} from '@/utils/plugins/grading';

const UiBottomSheet = defineAsyncComponent(
	() => import('@/components/ui/UiBottomSheet.vue')
);

const useGradingWidget = (
	filialId: number,
	gradingBottomSheet: Ref<typeof UiBottomSheet | null>,
	showCongrats: boolean
) => {
	let widget: IGradeLibraryInstance | null = null;

	/**
	 * Открыть окно с грейдированием
	 */
	const openGradingSheet = () => {
		gradingBottomSheet.value?.open();
	};

	/**
	 * Закрыть окно с грейдированием
	 */
	const closeGradingSheet = () => {
		gradingBottomSheet.value?.hide();
	};

	/**
	 * Отобразить грейдирование после задержки
	 */
	const showGrading = async (amount: number, orderId: number) => {
		if (!orderId) {
			return false;
		}

		if (!needDetermineGrading(filialId, orderId)) {
			return false;
		}

		widget = await initGrading(filialId, orderId, amount, showCongrats);
		if (!widget) {
			return false;
		}

		try {
			if (await widget.init()) {
				openGradingSheet();
				setTimeout(async () => {
					widget?.mount();
				}, 1000);

				return true;
			}
		} catch {
			return false;
		}
	};

	const removeGrading = () => {
		widget?.destroy();
	};

	return {
		openGradingSheet,
		closeGradingSheet,
		showGrading,
		removeGrading
	};
};

export default useGradingWidget;
