import { ICategory } from '@/types/interfaces/navigator';

export default {
	name: 'sf',
	value: 'categories.sfTitle',
	title: 'categories.sfTitle',
	position: 7,
	image:
		'https://rahmet.object.pscloud.io/static-files/assets/takeaway/img/sf.png',
	payload: {
		search: `SF`
	}
} as ICategory;
