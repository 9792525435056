import { FREQUENT_CATEGORY } from '../constants';
import { ProductsCategory } from '../models';
import i18n from '@/utils/plugins/i18n';
import { IProduct, IProductsCategory } from '@/types/interfaces/menu';

/**
 * Добавляет категорию любимых блюд в меню
 */
export default function (
	menu: IProductsCategory[],
	products: IProduct[]
): IProductsCategory[] {
	if (!products.length) {
		return menu;
	}

	const newMenu = JSON.parse(JSON.stringify(menu));
	const frequentCategory = new ProductsCategory(
		FREQUENT_CATEGORY.id,
		i18n.global.t(FREQUENT_CATEGORY.translateKey)
	);

	frequentCategory.addProducts(products);

	if (frequentCategory.hasProducts()) {
		newMenu.unshift(frequentCategory.getCategory());
	}

	return newMenu;
}
