import { IUserPayload } from '@/types/interfaces/user';
import { client } from '../../default.client';
import dayjs from 'dayjs';

/**
 * Обновление данных пользователя. Старый сервис
 */
export default function (payload: IUserPayload): Promise<void> {
	payload.bday = payload.bday
		? dayjs(payload.bday, 'DD/MM/YYYY').format('YYYY-MM-DD')
		: '';

	return client
		.put('api/v3/user', {
			data: {
				attributes: payload,
				type: 'user'
			}
		})
		.then(response => response.data)
		.catch(error => {
			console.error(error);
		});
}
