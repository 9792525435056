import { reactive } from 'vue';
import { getInitialCart } from './functions';
import { ICart } from './types';

interface IState {
	cart: ICart;
}

const state = reactive<IState>({
	cart: getInitialCart()
});

export { state, IState };
