import { client } from '../../default.client';

/**
 * Удаление адреса по id
 */
export default function (id: number): Promise<void> {
	return client
		.delete(`profile/api/v1/address/${id}`)
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}
