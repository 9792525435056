import { h } from 'vue';
import { UiSwitcherItem } from '@/components/ui/UiSwitcher';
import { TakeawayIcon, ExpressIcon, DeliveryIcon } from './icons';
import { SERVICES } from '@/utils/constants';
import { defineComponent } from 'vue';

const icons = {
	[SERVICES.takeaway.id]: TakeawayIcon,
	[SERVICES.express.id]: ExpressIcon,
	[SERVICES.delivery.id]: DeliveryIcon
};

export default function (component: typeof UiSwitcherItem, icon: string) {
	const iconComponent = () => h(icons[icon], { class: 'text-red-lite mr-1' });

	return defineComponent({
		name: 'UiSwitcherItemWithIcon',
		setup(_, { slots }) {
			return () =>
				h(component, null, {
					default: slots.default,
					icon: iconComponent
				});
		}
	});
}
