import { client } from '../../default.client';
import { ApiProxyJSONApiResponse } from '../../types/interfaces';
import { IUser } from '@/types/interfaces/user';
import dayjs from 'dayjs';

const getUserAdapter = (
	res: ApiProxyJSONApiResponse<IUser>
): ApiProxyJSONApiResponse<IUser> => {
	res.attributes.bday = res.attributes.bday
		? dayjs(res.attributes.bday, 'YYYY-MM-DD').format('DD/MM/YYYY')
		: '';
	return res;
};

/**
 * Получить пользователя
 */
export default function (): Promise<ApiProxyJSONApiResponse<IUser>> {
	return client
		.get(`api/v3/user`)
		.then(response => getUserAdapter(response.data.data))
		.catch(error => {
			throw error;
		});
}
