import { client } from '../../default.client';
import { AxiosResponse } from 'axios';
import { IBarcodeProduct } from '@/types/interfaces/menu';

export default function (filial_id: number, barcode: string) {
	return client
		.get<AxiosResponse<IBarcodeProduct>>(
			`menu/v3/product-by-barcode?filial_id=${filial_id}&barcode=${barcode}`
		)
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}
