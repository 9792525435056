import { client } from '../../default.client';

/**
 * Удалить аватар пользователя
 */
export default function (): Promise<void> {
	return client
		.delete(`api/v3/user/avatar`)
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}
