import { client } from '../../default.client';
import { AUTH_REQUEST } from '../../constants';
import { IAuthResponse } from '../../types/interfaces/auth';
import { CLIENT_ID } from '@/utils/constants';

/**
 * Авторизация через Track id
 */
export default function (trackId: string): Promise<IAuthResponse> {
	return client
		.post(AUTH_REQUEST, {
			client_id: CLIENT_ID,
			grant_type: 'authorization_track_id',
			track_id: trackId
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}
