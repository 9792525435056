import { PROMO_UNLIMITED_NUMBER } from '../constants';

/**
 * Для получения лимита акции
 */
export default function (
	productLimit: number,
	productDayLimit: number
): number {
	if (!productLimit && !productDayLimit) {
		return 0;
	}

	if (productLimit !== PROMO_UNLIMITED_NUMBER) {
		if (productDayLimit !== PROMO_UNLIMITED_NUMBER) {
			return productLimit > productDayLimit ? productDayLimit : productLimit;
		} else {
			return productLimit;
		}
	}

	if (productDayLimit !== PROMO_UNLIMITED_NUMBER) {
		return productDayLimit;
	}

	return PROMO_UNLIMITED_NUMBER;
}
