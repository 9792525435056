import { App, Plugin, DirectiveBinding } from 'vue';
import Lazy from 'vue3-lazy/dist/lib/core/lazy';
import { defaultPreset, IImageProxyOptions } from './default-preset';
import { getSource } from './image-proxy';

const imageProxyPlugin: Plugin = {
	install(app: App) {
		app.directive('img-proxy', {
			created(
				el: HTMLImageElement,
				binding: DirectiveBinding<IImageProxyOptions | string>
			) {
				const binds = binding.value;
				let options = defaultPreset;
				let fallback: string | undefined;

				if (typeof binds === 'string') {
					options.url = binds;
					fallback = binds;
				} else {
					options = { ...defaultPreset, ...binds };
					fallback = binds.url;
				}

				const proxyUrl = getSource(options);

				if (options.lazy) {
					binding.value = proxyUrl;
					const lazy = new Lazy({ error: fallback });
					lazy.add(el, binding);
				} else {
					el.src = proxyUrl;

					if (fallback) {
						el.onerror = function () {
							this.onerror = null;
							this.src = fallback;
						};
					}
				}
			}
		});
	}
};

export { imageProxyPlugin };
