enum DR_EVENTS {
	MINI_APP_OPENED = 'DR_miniapp_opened', // при открытии приложения
	WEB_AUTH_SUCCESS = 'DR_web_auth_success', // при успешной авторизации
	WEB_AUTH_SUCCESS_2 = 'auth_token_succeeded', // при успешной авторизации (отправлять без параметров)
	FILIAL_SELECTED = 'DR_filial_selected', // когда пользователь переходит в меню заведения
	MENU_OPENED = 'DR_menu_opened', // при открытии страницы меню
	CATEGORIES_CLICKED = 'DR_categories_clicked', // при клике на категорию меню
	MENU_CATEGORIES_LIST_OPENED = 'DR_menu_categories_list_opened', // при открытии списка категорий в меню
	SEARCH_CONFIRM = 'DR_search', // когда пользователь что-то вводит в поиске
	MENU_SEARCH_OPENED = 'DR_menu_search_opened', // при открытии страницы поиска по меню
	ADDED_TO_CART = 'DR_item_added', // при добавлении продукта в корзину
	COUNTED_TO_CART = 'DR_item_counted', // при нажатии на + в продукте
	CROSS_SELL_ADDED_TO_CART = 'DR_crosssell_item_added', // при добавлении рекомендации в корзину
	CHECKOUT_BEGAN = 'DR_basket_opened', // при открытии корзины
	CHECKOUT_ORDER_FORMED = 'DR_payment_requested', // при нажатии на "Оплатить"
	CHECKOUT_ORDER_SUCCESS = 'DR_payment_success', // при успешной оплате
	MAP_CLICKED = 'DR_map_clicked', // при клике на навигацию по карте
	LOCATION_DISTANCE = 'DR_location_distance', // при подтверждении дальности заказа
	LANGUAGE_CHANGED = 'DR_language_changed', // при смене языка
	TOWN_APPEARED = 'DR_town_appeared', // при открытии выбора города
	TOWN_SELECTED = 'DR_town_selected', // при выборе города
	TIPS_OPENED = 'DR_tips_payment_requested', // при открытии чаевых
	TIPS_SUCCESS = 'DR_tips_success', // при успешной оплате чаевых
	DOWNLOAD_APP = 'DR_download_app', // при клике на скачивание приложения
	TELEGRAM_BUTTON = 'DR_telegram_button', // при клике на Telegram
	BILL_PAGE_OPEN = 'DR_billingpage_open', // при открытии подтягивания счета
	MENU_HELP_CLICKED = 'DR_help_requested', // при нажатии на "Помощь" в меню
	BILL_PAYMENT_NOT_MY_BILL = 'DR_notmybill_button', // при нажатии на "Не мой счет" в подтягивании счета
	BILL_CALL_WAITER = 'DR_call_waiter', // при возникновении ошибки в подтягивании счета (страница "Позовите официанта")
	BARCODE_OPENED = 'DR_barcode_scan_opened', // при открытии сканера штрихкодов
	BARCODE_MANUAL_OPENED = 'DR_barcode_manual_opened', // при открытии ручного ввода штрихкодов
	BARCODE_ERROR = 'DR_barcode_scan_failed', // при ошибке сканера штрихкодов
	REVIEW_OPENED = 'DR_review_opened', // при клике на отзывы
	REVIEW_SENT = 'DR_review_sent', // при создании отзыва
	REVIEW_EDITED = 'DR_review_edited', // при изменении отзыва
	BILL_BUTTON_CLICKED = 'DR_bill_button_clicked', // кнопки подтягивания с указанием типа кнопки
	MENU_BUTTON_CLICKED = 'DR_menu_button_clicked', // при нажатии на кнопки меню
	MICRO_MARKET_UNLOCKED = 'DR_fridge_open', // при открытии микромаркета
	TABLET_OPENED = 'DR_tablet_opened', // при открытии планшета
	NEW_ORDER_REQUEST = 'DR_new_order_requested', // при нажатии на сделать новый заказ
	ADDRESS_CREATE_OPENED = 'DR_address_create_opened', // при открытии страницы создания адреса
	ADDRESS_CREATED = 'DR_address_created', // при создании адреса
	ADDRESS_SELECTED = 'DR_address_selected', // при выборе адреса
	BOOKING_CLICKED = 'DR_booking_clicked', // при клике на "забронировать стол"
	USER_NAME_FILLED = 'DR_user_name_filled', // при заполнении имени в профиле
	USER_BIRTHDAY_FILLED = 'DR_user_birthday_filled', // при заполнении дня рождения в профиле
	PROFILE_OPENED = 'DR_profile_opened', // при открытии профиля
	PROFILE_DELETE_CLICKED = 'DR_profile_delete_clicked', // при нажатии на "Удалить профиль"
	PROFILE_DELETED = 'DR_profile_deleted', // при удалении профиля
	STORIES_OPENED = 'DR_stories_opened' // при открытии сторис
}

export default DR_EVENTS;
