import { Router } from 'vue-router';
import { Vue } from '@sentry/vue/types/types';
import { IGNORE_ERRORS, API_ERROR_NAME, ALLOW_URLS } from './constants';
import {
	init,
	withScope,
	setUser,
	captureException,
	BrowserTracing,
	vueRouterInstrumentation
} from '@sentry/vue';
import { AuthService } from '@/services/auth';

const appEnv = import.meta.env.VITE_ENV;
const sentryDsn = import.meta.env.VITE_SENTRY_DSN;

/**
 * Возвращает полный url запроса
 * @param {object} response - Запрос
 * @returns {string}
 */
const getResponseUrl = (response: any) => {
	let url = response.config.url;

	if (!url.includes('https')) {
		url = `${response.config.baseURL}/${url}`;
	}

	return url;
};

export const SentryService = {
	init(app: Vue, router: Router) {
		if (appEnv === 'production') {
			init({
				app,
				environment: appEnv,
				dsn: sentryDsn,
				enableTracing: true,
				tracesSampleRate: 1.0,
				trackComponents: true,
				// @ts-ignore - по какой-то причине Абу не видит 'unmount' хотя в документации и типах он есть
				hooks: ['activate', 'create', 'destroy', 'mount', 'unmount', 'update'],
				integrations: [
					new BrowserTracing({
						routingInstrumentation: vueRouterInstrumentation(router, {
							routeLabel: 'path'
						})
					})
				],
				ignoreErrors: IGNORE_ERRORS,
				allowUrls: ALLOW_URLS,
				beforeSend(event, hint) {
					const error = hint.originalException;

					if (error && typeof error === 'object' && 'message' in error) {
						const message = error.message;

						if (
							typeof message === 'string' &&
							IGNORE_ERRORS.some(sentence => message.indexOf(sentence) !== -1)
						) {
							return null;
						}
					}

					return event;
				}
			});
		}
	},
	pushApiError(response: any) {
		withScope(scope => {
			const url = getResponseUrl(response);
			const name = `${API_ERROR_NAME}: ${url}`;
			const message = response.data?.message || response.message || '';
			const userId = AuthService.getUserId();

			// чтобы не показывать токен пользователя
			const headers = response.config.headers;
			delete headers.Authorization;

			const sentryUser = userId ? { id: userId } : null;
			setUser(sentryUser);
			scope.setLevel('error');
			scope.addBreadcrumb({
				category: 'data',
				level: 'info',
				message: JSON.stringify(
					{
						url,
						headers,
						params: response.config.params,
						request: JSON.parse(response.config.data || null),
						response: response.data || null
					},
					null,
					3
				)
			});

			const error = new Error(message);
			error.name = name;
			captureException(error);
		});
	},
	captureException(error: Error) {
		captureException(error);
	}
};
