import { ICategory } from '@/types/interfaces/navigator';

export default {
	name: 'near',
	value: 'categories.nearValue',
	title: 'categories.nearTitle',
	position: 3,
	payload: {
		url: 'near'
	}
} as ICategory;
