import { client } from '../../default.client';
import { JSON_API_HEADER } from '../../constants';
import { ApiProxyResponse } from '../../types/interfaces/api-proxy-response-data';
import {
	IAdditiveGroup,
	AdditiveGroupWithSelected
} from '@/types/interfaces/menu';

/**
 * Выставление min и max у дополнений в зависимости от родителя
 */
const adapter = (
	res: ApiProxyResponse<AdditiveGroupWithSelected>
): ApiProxyResponse<AdditiveGroupWithSelected> => {
	res.data.additive_groups = res.data.additive_groups.map(group => {
		group.relationships.forEach(add => {
			add.attributes.min = add.attributes.min || 0;
			add.attributes.max = add.attributes.max || group.attributes.max;
		});

		return group;
	});

	return res;
};

/**
 * Получение групп дополнений продукта
 */
const getProductAdditives = (
	filialId: number,
	productId: number,
	serviceType: number,
	abort?: AbortController
): Promise<ApiProxyResponse<AdditiveGroupWithSelected>> => {
	return client
		.get<ApiProxyResponse<IAdditiveGroup[]>>('menu/v2/product/additives', {
			params: {
				filial_id: filialId,
				product_id: productId,
				service_type: serviceType
			},
			headers: JSON_API_HEADER,
			signal: abort?.signal
		})
		.then(response => {
			return adapter({
				...response.data,
				data: {
					additive_groups: response.data.data
				}
			});
		})
		.catch(error => {
			throw error;
		});
};

/**
 * Получение групп дополнений продукта с заранее выбранными элементами
 */
const getProductAdditivesAuth = (
	filialId: number,
	productId: number,
	serviceType: number,
	abort?: AbortController
): Promise<ApiProxyResponse<AdditiveGroupWithSelected>> => {
	return client
		.get<ApiProxyResponse<AdditiveGroupWithSelected>>(
			'menu/v2/favourite-product/additives',
			{
				params: {
					filial_id: filialId,
					product_id: productId,
					service_type: serviceType
				},
				headers: JSON_API_HEADER,
				signal: abort?.signal
			}
		)
		.then(response => adapter(response.data))
		.catch(error => {
			throw error;
		});
};

export { getProductAdditives, getProductAdditivesAuth };
