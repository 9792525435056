import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { IScheduleItem } from '@/types/interfaces/common';

dayjs.extend(customParseFormat);

/**
 * Форматирует график с учетом минут до закрытия точки
 */
export default function (schedule: IScheduleItem[], minutesToClose = 0) {
	const copy: IScheduleItem[] = JSON.parse(JSON.stringify(schedule));

	if (!minutesToClose) {
		return copy;
	}

	return copy.map(item => {
		const temp = { ...item };

		if (temp.opening_hours) {
			const lastHour = temp.opening_hours[temp.opening_hours.length - 1];
			lastHour.to = dayjs(lastHour.to, 'HH:mm')
				.subtract(minutesToClose, 'minute')
				.format('HH:mm');
		}

		return temp;
	});
}
