import { ICoordinates } from '@/types/interfaces/common';

/**
 * Расчет дистанции между двумя координатами по Haversine formula
 * @param coordinates1 - координаты первой точки
 * @param coordinates2 - координаты второй точки
 * @returns дистанция в метрах
 */
export default function (
	coordinates1: ICoordinates,
	coordinates2: ICoordinates
) {
	if (!(coordinates1 && coordinates2)) {
		return 0;
	}

	const { latitude: lat1, longitude: lon1 } = coordinates1;
	const { latitude: lat2, longitude: lon2 } = coordinates2;

	if (lat1 === lat2 && lon1 === lon2) {
		return 0;
	}

	const radlat1 = (Math.PI * lat1) / 180;
	const radlat2 = (Math.PI * lat2) / 180;
	const theta = lon1 - lon2;
	const radtheta = (Math.PI * theta) / 180;
	let dist =
		Math.sin(radlat1) * Math.sin(radlat2) +
		Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
	if (dist > 1) {
		dist = 1;
	}
	dist = Math.acos(dist);
	dist = (dist * 180) / Math.PI;
	dist = dist * 60 * 1.1515 * 1.609344 * 1000;

	return dist;
}
