import { client } from '../../default.client';
import { JSON_API_HEADER } from '../../constants';
import { ApiProxyResponse } from '../../types/interfaces/api-proxy-response-data';
import { IProductsCategory } from '@/types/interfaces/menu';

interface Response {
	id: number;
	type: string;
	relationships: IProductsCategory[];
	attributes: {
		external_id: string;
	};
}

/**
 * Получение категорий меню
 */
export default function (
	filialId: number,
	abort?: AbortController
): Promise<ApiProxyResponse<Response>> {
	const config = {
		headers: JSON_API_HEADER,
		signal: abort?.signal
	};

	return client
		.get(`menu/v2/category/products?filial_id=${filialId}`, { ...config })
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}
