import { IDiProvider } from '../../types';
import WebApplication from './web-application.provider';
import WebViewApplication from './webview-application.provider';
import ReactNativeApplication from './react-native-application.provider';

const providerKey = 'application';

export default {
	key: providerKey,
	value() {
		if (window.RahmetApp) {
			return WebViewApplication;
		}

		if (window.ReactNativeWebView) {
			return ReactNativeApplication;
		}

		return WebApplication;
	}
} as IDiProvider;
