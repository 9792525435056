import { ICoordinates } from '@/types/interfaces/common';

const GEOLOCATION_SETTINGS = {
	enableHighAccuracy: true,
	timeout: 5000,
	maximumAge: 0
};

/**
 * Получение координат пользователя
 */
export default function (): Promise<ICoordinates> {
	return new Promise((resolve, reject) => {
		window.navigator.geolocation.getCurrentPosition(
			({ coords }) => {
				resolve({
					latitude: coords.latitude,
					longitude: coords.longitude
				});
			},
			error => reject(error),
			GEOLOCATION_SETTINGS
		);
	});
}
