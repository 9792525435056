import { KEYS } from '../keys';
import { CartPositionModel } from '../models';
import { ICart } from '../types';

const DEFAULT_CART: ICart = {
	external_id: undefined,
	filial_id: 0,
	service: undefined,
	service_type: 0,
	service_percentage: 0,
	products: [],
	comment: ''
};

/**
 * Устанавливает свойство для корзины
 */
const setCartPropertyIfExist = (
	initial: ICart,
	duplicate: any,
	key: keyof ICart
) => {
	let value = duplicate[key];

	if (value) {
		if (key === 'products' && Array.isArray(value)) {
			value = value.map(el => new CartPositionModel({ ...el }));
		}

		// @ts-ignore
		initial[key] = value;
	}
};

/**
 * Для получения исходной корзины
 */
export default function (): ICart {
	const cart = DEFAULT_CART;
	const storageCart = JSON.parse(window.sessionStorage.getItem(KEYS.cart)!);

	if (!storageCart) {
		return cart;
	}

	if (typeof storageCart !== 'object') {
		return cart;
	}

	setCartPropertyIfExist(cart, storageCart, 'filial_id');
	setCartPropertyIfExist(cart, storageCart, 'service');
	setCartPropertyIfExist(cart, storageCart, 'service_type');
	setCartPropertyIfExist(cart, storageCart, 'service_percentage');
	setCartPropertyIfExist(cart, storageCart, 'products');
	setCartPropertyIfExist(cart, storageCart, 'comment');
	setCartPropertyIfExist(cart, storageCart, 'external_id');

	return cart;
}
