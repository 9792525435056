import { client } from '../../default.client';
import {
	ApiProxyResponse,
	TelegramUserNotificationResponse
} from '../../types/interfaces';

/**
 * Возвращает данные о чате уведомлений в Telegram
 * @name getNotificationChat
 * @route {GET} telegram-user-notifications/v1/chat
 */
export default function () {
	const url = 'telegram-user-notifications/v1/chat';

	return client
		.get<ApiProxyResponse<TelegramUserNotificationResponse>>(url)
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}
