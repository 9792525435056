import { reactive } from 'vue';
import { GeoStatus } from 'choco-app';
import { KEYS, PLATFORMS } from './types';
import { DOCUMENT_TITLE } from '@/utils/constants';
import providers from '@/utils/plugins/di';
import i18n from '@/utils/plugins/i18n';
import { ICoordinates, IService } from '@/types/interfaces/common';

const storage = window.localStorage;

interface IState {
	townId: number;
	platform: PLATFORMS;
	version: string;
	deviceId: string;
	geoStatus: GeoStatus;
	coordinates: ICoordinates | null;
	title: string;
	service: IService;
}

/**
 * Получения сервиса по умолчанию
 */
const getDefaultService = (): IService => {
	const id: string = localStorage.getItem(KEYS.SERVICE) || '';
	return providers.application.getServiceById(id);
};

const state = reactive<IState>({
	townId: JSON.parse(storage.getItem(KEYS.TOWN_ID)!) || 1,
	platform: (storage.getItem(KEYS.PLATFORM) as PLATFORMS) || PLATFORMS.WEB,
	version: storage.getItem(KEYS.VERSION) || '',
	deviceId: storage.getItem(KEYS.DEVICE_ID) || '',
	geoStatus: GeoStatus.granted,
	coordinates: null,
	title: i18n.global.t(DOCUMENT_TITLE),
	service: getDefaultService()
});

export { IState, state };
