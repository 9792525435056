import { ICategory } from '@/types/interfaces/navigator';

export default {
	name: 'popular',
	value: 'categories.popularValue',
	title: 'categories.popularTitle',
	position: 4,
	image:
		'https://rahmet.object.pscloud.io/static-files/assets/takeaway/img/popular.png',
	payload: {
		url: 'popular',
		sort: 'rating'
	}
} as ICategory;
