import { client } from '../../default.client';
import { ITerminalResponse } from '../../types/interfaces/get-filial-response';
import { ApiProxyResponse } from '../../types/interfaces';
/**
 * Получение terminal name (без авторизации)
 */
export default async function (
	filialId: string | number
): Promise<ApiProxyResponse<ITerminalResponse>> {
	return await client
		.get<ApiProxyResponse<ITerminalResponse>>(`terminals/terminals/${filialId}`)
		.then(response => response.data);
}
