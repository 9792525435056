import { client } from '../../default.client';
import { JSON_API_HEADER } from '../../constants';
import { ApiProxyResponse } from '../../types/interfaces/api-proxy-response-data';
import { IProduct } from '@/types/interfaces/menu';

/**
 * Получение связанных продуктов
 */
export default function (
	filialId: number,
	productIds: number[]
): Promise<IProduct[]> {
	return client
		.get('menu/v3/cross-sell/products', {
			params: { filial_id: filialId, products: productIds },
			headers: JSON_API_HEADER
		})
		.then(response => {
			const responseData: ApiProxyResponse<IProduct[]> = response.data;
			return responseData.data;
		})
		.catch(error => {
			throw error;
		});
}
