import { client } from '../../default.client';
import { JSON_API_HEADER } from '../../constants';
import { AuthService } from '@/services/auth';
import { IPromo, IPromoProduct } from '@/types/interfaces/common';

interface PromosResponse {
	data: IPromo[];
	included?: IPromoProduct[];
}

/**
 * Возвращает список акций
 */
export default function (filialId: number, abort?: AbortController) {
	const params = {
		'filter[filial_id]': filialId,
		'filter[onlyActive]': true,
		include: 'products',
		user_id: AuthService.getUserId()
	};

	const config = {
		headers: JSON_API_HEADER,
		signal: abort?.signal,
		params
	};

	return client
		.get<PromosResponse>('menu-promos/api/v2/promos', { ...config })
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}
