import { createApp, App } from 'vue';
import UiNotification from '@/components/ui/UiNotification';
import { NotificationKey } from '@/types/symbols';

const notificationPlugin = {
	install(app: App) {
		const mountPoint = document.createElement('div');
		document.body.appendChild(mountPoint);
		const container = createApp(UiNotification).mount(mountPoint);
		app.config.globalProperties.$notification = container;
		app.provide(NotificationKey, container);
	}
};

export { notificationPlugin };
