import { client } from '../../default.client';
import { ApiProxyJSONApiResponse } from '../../types/interfaces';
import {
	PrivateRewardBalance,
	PrivateRewardBalancePayload
} from '@/types/interfaces/balance';

export default function (payload: PrivateRewardBalancePayload) {
	return client
		.get<{ data: ApiProxyJSONApiResponse<PrivateRewardBalance> }>(
			'/ladder-go/api/v1/balance/calculate-info',
			{ params: payload }
		)
		.then(response => response.data.data)
		.catch(error => {
			throw error;
		});
}
