<template>
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.6685 6L12.9219 6.66667"
			stroke="currentColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M3.08927 6.66667L1.33594 6"
			stroke="currentColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12.1667 9.33724H10.5"
			stroke="currentColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M5.5026 9.33724H3.83594"
			stroke="currentColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12.9206 6.66797L12.3613 3.9933C12.1993 3.2213 11.5186 2.66797 10.7293 2.66797H5.3426C4.5686 2.66797 3.89594 3.2013 3.71994 3.9553L3.08594 6.66797"
			stroke="currentColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M2.16927 13.3346H3.66927C4.22127 13.3346 4.66927 12.8866 4.66927 12.3346V11.668H11.3359V12.3346C11.3359 12.8866 11.7839 13.3346 12.3359 13.3346H13.8359C14.2959 13.3346 14.6693 12.9613 14.6693 12.5013V8.66797C14.6693 7.5633 13.7739 6.66797 12.6693 6.66797H3.33594C2.23127 6.66797 1.33594 7.5633 1.33594 8.66797V12.5013C1.33594 12.9613 1.70927 13.3346 2.16927 13.3346Z"
			stroke="currentColor"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
