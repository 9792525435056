import { reactive } from 'vue';
import {
	getBill,
	getFilialToken,
	createPreorder,
	getBillDetails,
	getReward,
	getFilial
} from '@/services/api';
import { getTerminal } from '@/services/api/requests/locations';
import {
	IGetFilialResponse,
	ITerminalResponse
} from '@/services/api/types/interfaces/get-filial-response';
import { ClientService } from '@/services/client';
import {
	IFilialToken,
	IPreorder,
	BillPreorderPayload
} from '@/services/bill/types';
import { IBillDetails } from '@/types/interfaces/orders';
import { IReward } from '@/types/interfaces/loyalty';
import { ApiProxyResponse } from '../api/types/interfaces';

const state = reactive<{ billInfo: IBillDetails | null }>({
	billInfo: null
});

interface IBillService {
	fetchBillInfo: (
		tableId: number,
		terminalId: string,
		strict?: boolean
	) => Promise<IBillDetails>;
	fetchFilialToken: (
		filialId: number
	) => Promise<ApiProxyResponse<IFilialToken>>;
	fetchPreorder: (
		payload: BillPreorderPayload
	) => Promise<ApiProxyResponse<IPreorder>>;
	fetchReward: (
		amount: number,
		terminalId: number
	) => Promise<ApiProxyResponse<IReward>>;
	fetchBillInfoDetails: (preorder: string) => Promise<IBillDetails>;
	getTotalSumWithDiscount: (
		rewardType: string,
		price: number,
		rewardAmount: number
	) => number;
	fetchFilial: (
		mainTerminalId: number
	) => Promise<ApiProxyResponse<IGetFilialResponse>>;
	fetchTerminalName: (
		mainTerminalId: number
	) => Promise<ApiProxyResponse<ITerminalResponse>>;
}

/**
 * Получение счета
 */
const fetchBillInfo = async (
	tableId: number,
	terminalId: string,
	strict = false
): Promise<IBillDetails> => {
	if (!strict && state.billInfo) {
		return Promise.resolve(state.billInfo);
	}

	try {
		const bill = await getBill(tableId, terminalId);
		state.billInfo = bill;
		return bill;
	} catch (error) {
		throw new Error('Failed to fetch bill info');
	}
};

/**
 * Получение счета на TYP
 */
const fetchBillInfoDetails = async (preorder: string) => {
	try {
		const bill = await getBillDetails(preorder);
		return bill;
	} catch (error) {
		throw new Error('Failed to fetch BillInfoDetails');
	}
};

/**
 * Получение токена филиала
 */
const fetchFilialToken = (filialId: number) => {
	return getFilialToken(filialId);
};

/**
 * Получение преордера
 */
const fetchPreorder = async (payload: BillPreorderPayload) => {
	const res = await createPreorder(payload);

	return res;
};
/**
 * Получение кешбэка
 */
const fetchReward = async (amount: number, terminalId: number) => {
	const res = await getReward(amount, terminalId);

	return res;
};

const getTotalSumWithDiscount = (
	rewardType: string,
	price: number,
	rewardAmount: number
) => {
	if (price && rewardAmount) {
		if (
			rewardType === 'discount_percentage' ||
			rewardType === 'discount_amount'
		) {
			return price - rewardAmount;
		}
	}
	return price;
};

const fetchFilial = (mainTerminalId: number) => {
	return getFilial({
		filial_id: mainTerminalId,
		coordinates: ClientService.state.coordinates
	});
};

const fetchTerminalName = (mainTerminalId: number) => {
	return getTerminal(mainTerminalId);
};

const BillService: IBillService = {
	fetchFilialToken,
	fetchPreorder,
	fetchBillInfo,
	fetchReward,
	fetchBillInfoDetails,
	getTotalSumWithDiscount,
	fetchFilial,
	fetchTerminalName
};

export { BillService };
