import { RouteLocationNormalizedLoaded, NavigationGuardNext } from 'vue-router';
import { delivery } from '@/utils/plugins/delivery';
import { ADDRESS_MAP_URL, ADDRESS_FORM_URL } from '../constants';

const blackList: string[] = [ADDRESS_MAP_URL, ADDRESS_FORM_URL];

export default function (
	to: RouteLocationNormalizedLoaded,
	from: RouteLocationNormalizedLoaded,
	next: NavigationGuardNext
) {
	let path = '';
	const fromName = from.name;

	// есть вероятность, что Vue Router еще не готов
	if (!fromName) {
		path = window.location.href.replace(window.location.origin, '');
	} else {
		path = from.fullPath;
	}

	if (blackList.every(url => !path.includes(url))) {
		delivery.service().setRedirectUrl(path);
	}

	return next();
}
