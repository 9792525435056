import { client } from '../../default.client';
import { AuthService } from '@/services/auth';
import { NEAR_CATEGORY } from '@/services/navigator/constants';
import { SERVICES } from '@/utils/constants';
import { wrapPaginationData } from '@/utils/functions';
import { ICoordinates, IPaginationWrapper } from '@/types/interfaces/common';
import { IFilial } from '@/types/interfaces/navigator';

export interface IGetFilialsPayload {
	service?: string;
	onlyPromo?: boolean;
	url?: string;
	page?: number;
	limit?: number;
	sort?: string;
	user_id?: number;
	include?: string;
	town?: number;
	search?: string;
	bbox?: string;
	brand_id?: string;
	coordinates?: ICoordinates | null;
	category_id?: number;
}

interface IGetFilialsParams {
	'filter[service]'?: number;
	'filter[only-promo]': number;
	page: number;
	limit: number;
	sort: string;
	user_id: string | null;
	include?: string;
	town_id?: number;
	search?: string;
	bbox?: string;
	category_id?: number | null;
	brand_id?: string;
}

/**
 * Возвращает полный URL для getFilials
 */
const getFullUrl = (url?: string): string => {
	if (url === NEAR_CATEGORY.name) {
		return 'brandy/api/v1/locations/near';
	}

	let fullUrl = 'takeaway/v2/locations';

	if (url) {
		fullUrl += `/${url}`;
	}

	return fullUrl;
};

/**
 * Возвращает отформатированный список филиалов из навигатора
 */
export default function (
	payload: IGetFilialsPayload,
	abort?: AbortController
): Promise<IPaginationWrapper<IFilial>> {
	const url = getFullUrl(payload.url);
	const headers = payload.coordinates
		? {
				'X-Latitude': payload.coordinates.latitude,
				'X-Longitude': payload.coordinates.longitude
		  }
		: {};

	const params: IGetFilialsParams = {
		'filter[service]': undefined,
		'filter[only-promo]': payload.onlyPromo ? 1 : 0,
		page: payload.page || 1,
		limit: payload.limit || 20,
		sort: payload.sort || 'distance',
		user_id: AuthService.getUserId(),
		include: payload.include,
		town_id: payload.town,
		search: payload.search,
		bbox: payload.bbox,
		category_id: payload.category_id,
		brand_id: payload.brand_id
	};
	if (payload.service) {
		switch (payload.service) {
			// в ресторане
			case SERVICES.express.id: {
				// if (!params.category_id) {
				// 	params.category_id = null;
				// }

				params.category_id = 128;
				break;
			}

			// доставка
			case SERVICES.delivery.id: {
				params['filter[service]'] = 8;
				break;
			}

			// с собой
			default: {
				params['filter[service]'] = 4;
			}
		}
	}

	// eslint-disable-next-line
	return client
		.get(url, { headers, params, signal: abort?.signal })
		.then(response => wrapPaginationData(response.data))
		.catch(error => {
			throw error;
		});
}
