<template>
	<div class="min-h-full flex flex-col viewport-max-width">
		<!-- Шапка -->
		<BaseHeader
			:title="title"
			:show-button-back="!hideButtonBack"
			fixed
			@back="goToPrevPage"
		/>

		<div class="flex-1">
			<slot></slot>
		</div>
	</div>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import BaseHeader from '@/components/base/BaseHeader';
import { ClientService } from '@/services/client';
import { MenuService } from '@/services/menu';
import { useNavigation } from '@/utils/composables';
import { isSourceValid } from '@/utils/functions';
import { SOURCES } from '@/types/enums/common';

const HIDDEN_ROUTE_NAMES = ['Auth', 'Brands'];

/**
 * @module layouts/DefaultLayout
 * @vue-computed {string} title - Заголовок
 */
export default {
	name: 'DefaultLayout',
	components: {
		BaseHeader
	},
	setup() {
		const route = useRoute();
		const { goToPrevPage } = useNavigation();
		const title = computed(() => ClientService.state.title);

		const hideButtonBack = computed(() => {
			const routeName = route.name;
			if (!routeName) {
				return true;
			}

			if (HIDDEN_ROUTE_NAMES.includes(routeName)) {
				return true;
			}

			if (routeName === 'Menu') {
				const source = MenuService.state.tempFilialData.source;
				return !!source;
			}

			if (routeName === 'OrderInformationStatuses') {
				const source = MenuService.state.tempFilialData.source;
				return isSourceValid(source, SOURCES.TABLET);
			}

			return false;
		});

		return {
			goToPrevPage,
			title,
			hideButtonBack
		};
	}
};
</script>
