import { ref } from 'vue';

const isNavbarVisible = ref(false);

export default function () {
	const setNavbarVisibility = (value: boolean) => {
		isNavbarVisible.value = value;
	};

	return {
		isNavbarVisible,
		setNavbarVisibility
	};
}
