import CommonApplication from './common-application.provider';
import { getNotificationChat } from '@/services/api';
import { AuthService } from '@/services/auth';
import { TCallback } from '@/utils/plugins/di/types';
import { APP_RUNTIME } from '@/types/enums/common';

const telegramBotUrl = import.meta.env.VITE_TELEGRAM_BOT;

class WebApplication extends CommonApplication {
	constructor(runtimeName: APP_RUNTIME) {
		super(runtimeName);
	}

	becomingActive(callback: TCallback): void {
		document.addEventListener('visibilitychange', () => {
			this.onVisibilityChange(callback);
		});
	}

	onVisibilityChange(callback: TCallback): void {
		if (document.visibilityState === 'visible') {
			callback();
		}
	}

	becomingHidden(): void {
		document.removeEventListener(
			'visibilitychange',
			this.onVisibilityChange as any
		);
	}

	getNotificationChatLink(): Promise<string> {
		return getNotificationChat()
			.then(() => telegramBotUrl)
			.catch(error => {
				const statusCode = error.response.data.error_code;

				if (statusCode === 404) {
					const userId = AuthService.getUserId();
					return `${telegramBotUrl}?start=${userId}`;
				}

				throw error;
			});
	}

	// Обернул в Promise, так как нужно время для обновления страницы/редиректа на Oauth
	authorize(): Promise<void> {
		return new Promise(AuthService.goToOauth);
	}

	openExternalLink = (url: string): void => {
		window.open(url, '_blank');
	};
}

export default new WebApplication(APP_RUNTIME.WEB);
export { WebApplication };
