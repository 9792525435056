import { client } from '../../default.client';
import { RyadomCart } from '@/types/interfaces/ryadom';

const getRyadomCart = (
	warehouseId: string,
	deviceId: string
): Promise<RyadomCart> => {
	return client
		.get(`/cart/v1/guest/cart?warehouse_id=${warehouseId}`, {
			headers: {
				'X-Device-Id': deviceId
			}
		})
		.then(res => res.data.data)
		.catch(error => {
			throw error;
		});
};
const getRyadomCartAuth = (warehouseId: string): Promise<RyadomCart> => {
	return client
		.get(`/cart/v1/cart?warehouse_id=${warehouseId}`)
		.then(res => res.data.data)
		.catch(error => {
			throw error;
		});
};

export { getRyadomCart, getRyadomCartAuth };
