<template>
	<div
		ref="root"
		class="ui-switcher relative bg-grey-lite"
		:style="{ padding: `${offset}px`, borderRadius: `${borderRadius}px` }"
	>
		<div class="flex ui-switcher__container">
			<slot></slot>
		</div>

		<div
			class="ui-switcher__island absolute min-h-10 bg-white"
			:class="{ 'toggler-animation': canAnimate }"
			:style="{
				top: `${togglerStyle.top}px`,
				width: `${togglerStyle.width}px`,
				transform: `translateX(${togglerStyle.translateX}px)`,
				minHeight: `${ISLAND_HEIGHT}px`,
				borderRadius: `${islandBorderRadius}px`
			}"
		></div>
	</div>
</template>

<script setup lang="ts">
import { ref, reactive, provide, onMounted, nextTick } from 'vue';
import { ISLAND_HEIGHT } from './constants';
import {
	SwitcherIsActiveKey,
	SwitcherNameKey,
	SwitcherOnClickKey
} from './symbols';
import { debounce } from '@/utils/functions';

interface Props {
	name?: string;
	defaultId?: string;
	offset?: number;
	delay?: number;
	islandBorderRadius?: number;
	borderRadius?: number;
}

const props = withDefaults(defineProps<Props>(), {
	name: 'switcher',
	defaultId: '',
	offset: 4,
	delay: 0,
	borderRadius: 16,
	islandBorderRadius: 14
});
const emit = defineEmits<{ (e: 'select', id: any): void }>();

const root = ref<HTMLElement | null>(null);
const currentTabId = ref('');
const canAnimate = ref(false);
const inputSelector = `input[name=${props.name}]`;
const checkedInputSelector = inputSelector + ':checked';
const togglerStyle = reactive({
	top: props.offset,
	width: 0,
	translateX: 0
});

const initCurrentTabId = () => {
	if (props.defaultId) {
		currentTabId.value = props.defaultId;
		return;
	}

	const firstInput = root.value?.querySelector(inputSelector);
	if (firstInput) {
		currentTabId.value = firstInput.getAttribute('data-id') || '';
	}
};

const updateToggler = () => {
	const selectedInput = root.value?.querySelector(checkedInputSelector);

	if (selectedInput?.parentElement) {
		setTogglerStyle(selectedInput?.parentElement);
	}
};

const setTogglerStyle = (el: HTMLElement) => {
	togglerStyle.width = el.offsetWidth;
	togglerStyle.translateX = el.offsetLeft - props.offset;
};

const emitSelect = debounce(id => {
	emit('select', id);
}, props.delay);

const onItemClick = (id: string) => {
	if (currentTabId.value === id) {
		return;
	}

	currentTabId.value = id;
	canAnimate.value = true;

	updateToggler();
	emitSelect(id);
};

const isItemActive = (id: string) => props.defaultId === id;

provide(SwitcherNameKey, props.name);
provide(SwitcherOnClickKey, onItemClick);
provide(SwitcherIsActiveKey, isItemActive);

onMounted(() => {
	nextTick(() => {
		initCurrentTabId();
		updateToggler();
	});
});
</script>

<style lang="scss" scoped>
.toggler-animation {
	transition: transform 0.2s ease-in-out;
}

@media screen and (max-width: 460px) {
	.ui-switcher__container:has(:nth-child(3)) {
		font-size: 0.875rem;
	}
}

@media screen and (max-width: 420px) {
	.ui-switcher__container:has(:nth-child(3)) {
		font-size: 0.75rem;
	}
}
</style>
