import { IScheduleItem } from '@/types/interfaces/common';
import { WEEK_DAYS } from '@/utils/constants';

/**
 * Получение дня из графика
 */
export default function (
	weekday: number,
	schedule: IScheduleItem[]
): IScheduleItem {
	return (
		schedule.find(item => WEEK_DAYS[item.prefix_en] === weekday) || schedule[0]
	);
}
