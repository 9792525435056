import { IHours } from '@/types/interfaces/common';

const DAY_AND_NIGHT_HOURS_LIST: IHours[] = [
	{ from: '00:00', to: '00:00' },
	{ from: '00:00', to: '23:59' }
];

/**
 * Круглосуточный ли день
 */
export default function (hours: IHours) {
	return DAY_AND_NIGHT_HOURS_LIST.some(h => {
		return h.from === hours.from && h.to === hours.to;
	});
}
