import { client } from '../../default.client';
import { ICfUserAddress } from '@/types/interfaces/chocofood';
import { getAddressAdapter } from '../../adapters/user';
import { IUserAddress } from '@/types/interfaces/user';

/**
 * Создание адреса
 */
export default function (address: ICfUserAddress): Promise<ICfUserAddress> {
	const payload: Omit<IUserAddress, 'id'> = {
		latitude: address.coordinates.latitude,
		longitude: address.coordinates.longitude,
		comment: address.comment || '',
		entrance: address.entrance || '',
		floor: address.floor || '',
		apartment: address.apartment || '',
		building: address.building || '',
		street: address.street || '',
		city: address.city || '',
		title: address.title || ''
	};

	return client
		.post('profile/api/v1/address', payload)
		.then(response => getAddressAdapter(response.data.data))
		.catch(error => {
			throw error;
		});
}
